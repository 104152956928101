import React, { useCallback } from 'react';

import { 
    HeaderIcon,
} from './HeaderIcon';

import LanguageSelector from './LanguageSelector';

import { styled, useTheme } from '@mui/material/styles';
import {
    AppBar,
    Toolbar,
} from '@mui/material';

import "../../styles/button.css";

import { useTranslation } from 'react-i18next';

import { useNavigationForLang } from '../../i18n';

const HomeHeader = () => {
    const { t } = useTranslation("authentication");
    const theme = useTheme();

    const handleNavigation = useNavigationForLang();

    return (
        <AppBar 
            sx={{
                width: '100%',
                backgroundColor: 'white',
                boxShadow: { xs: 0, md: 1, lg: 2 },
                zIndex: { xs: 1000, md: 1200, lg: 1400 },
                px: { xs: 3, md: 6, lg: 12 },
            }}
            position="absolute" 
        >
            <Toolbar 
                disableGutters
                sx={{
                    maxWidth: '1600px',
                    mx: 'auto',
                    display: 'flex',
                    width: '100%',
                    height: '64px',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                }}
            >
                <HeaderIcon
                    theme={theme}
                    onClickLogo={() => handleNavigation('/')}
                    color="black"
                />
                <div className="flex-grow" />
                <div className="pr-4">
                    <button 
                        className="text-button"
                        onClick={() => handleNavigation('/signin')}
                    >
                        {t("signin")}
                    </button>
                </div>
                <LanguageSelector />
            </Toolbar>
        </AppBar>
    );
};

export default HomeHeader;
