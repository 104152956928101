import React, { useState, useEffect } from "react";
import { Box, Typography, Grid, Paper } from "@mui/material";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer
} from "recharts";
import { fetchAWSInstanceMetrics } from "../../../services/InstanceServices";

import { useTranslation } from "react-i18next";
import { useNavigationForLang } from "../../../i18n";

export default function InstanceResourceMonitoring({ iid }) {
  const [data, setData] = useState([]);

  const { t } = useTranslation("project");
  const handleNavigation = useNavigationForLang();

  useEffect(() => {
    const fetchData = async () => {
        try {
            const response = await fetchAWSInstanceMetrics(iid);
            const result = await response.json();

            if (result.cpu && result.network_in && result.network_out && result.memory) {
                const mergedData = result.cpu.map((item, index) => ({
                    time: item.time,
                    cpu: item.value,
                    network_in: result.network_in[index]?.value || 0,
                    network_out: result.network_out[index]?.value || 0,
                    memory: result.memory[index]?.value || 0
                }));

                setData(mergedData.slice(-24));  // 🔥 최근 24개 데이터 유지
                // console.log("Data fetched:", mergedData);
            } else {
                console.warn("Invalid data format received:", result);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    fetchData();
    const interval = setInterval(fetchData, 60000); // 🔥 5분마다 데이터 갱신
    return () => clearInterval(interval);
    }, [iid]);


  return (
    <div>
      <Typography variant="h5" sx={{ mb: 1 }}>
        {t("resourceMonitoring")}
      </Typography>
      <Grid container spacing={2} sx={{ width: "100%", mt: 2 }}>
        {/* CPU 사용량 그래프 */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold", textAlign: "center" }}>
              {t("cpuUsage")} (%)
            </Typography>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tickMargin={5} />
                <YAxis domain={[0, 100]} tickMargin={5} />
                <Tooltip />
                <Line type="monotone" dataKey="cpu" stroke="#000000" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Network 사용량 그래프 */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold", textAlign: "center" }}>
              {t("networkUsage")} (Bytes)
            </Typography>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tickMargin={5} />
                <YAxis domain={[0, Math.max(...data.map(d => Math.max(d.network_in, d.network_out)))]} tickMargin={5} />
                <Tooltip />
                <Line type="monotone" dataKey="network_in" stroke="#FF0000" strokeWidth={2} />
                <Line type="monotone" dataKey="network_out" stroke="#0000FF" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>

        {/* Memory 사용량 그래프 */}
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2 }}>
            <Typography variant="body1" sx={{ mb: 1, fontWeight: "bold", textAlign: "center" }}>
              {t("memoryUsage")} (%)
            </Typography>
            <ResponsiveContainer width="100%" height={200}>
              <LineChart data={data} margin={{ top: 10, right: 10, left: 10, bottom: 5 }}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="time" tickMargin={5} />
                <YAxis domain={[0, 100]} tickMargin={5} />
                <Tooltip />
                <Line type="monotone" dataKey="memory" stroke="#008000" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </div>
  );
}
