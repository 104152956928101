import React, {useState} from 'react';

import { Language } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const languages = [
    { code: "en", label: "English" },
    { code: "ko", label: "한국어" },
];


const LanguageSelector = ({color = "#000000"}) => {
    const { i18n } = useTranslation();
    const { lang } = useParams();
    const loc = useLocation();
    const navigate = useNavigate();
    
    const [isOpen, setIsOpen] = useState(false);

    const getLabel = (code) => {
        const language = languages.find((lang) => lang.code === code);
        return language ? language.label : "English"; // 기본값 English
    };
      
    const [selectedLang, setSelectedLang] = useState(getLabel(lang || i18n.language));

    const changeLanguage = (code, label) => {
        // console.log("현재 경로:", loc.pathname);

        i18n.changeLanguage(code);
        setSelectedLang(label);

        const pathParts = location.pathname.split("/").filter(Boolean); // `/` 제거 후 배열화
        // console.log("pathParts:", pathParts);
    
        if (pathParts.length > 0 && languages.some(lang => lang.code === pathParts[0])) {
          pathParts.shift(); // ✅ 첫 번째 요소가 언어 코드라면 제거
        }
    
        let newPathname = `/${pathParts.join("/")}`;
        if (newPathname === "/") newPathname = "/home"; // 기본 경로 설정
    
        // console.log("newPathname:", newPathname);

        // ✅ 영어(en)인 경우 언어를 URL에서 제거
        if (code === "en") {
            navigate(newPathname || "/");
        } else {
            navigate(`/${code}${newPathname}`);
        }
    }

    return (
        <div className="relative inline-block text-left w-fit">
            <button
                onClick={() => setIsOpen(!isOpen)}
                // className="flex w-36 items-center gap-2 py-2 text-black rounded-md"
                className="flex w-full items-center gap-2 py-2 text-black rounded-md"
                style={{ fontSize: "14px", color: color }}
            >
                <Language style={{color: color}}/> {selectedLang} {!isOpen ? "▼" : "▲"}
            </button>

            {isOpen && (
                <div className="absolute right-0 mt-2 w-24 bg-white border rounded-md shadow-lg ">
                    {languages.map((lang) => (
                        <button
                            key={lang.code}
                            onClick={() => { changeLanguage(lang.code, lang.label); setIsOpen(false); }}
                            className="block w-full px-4 py-2 text-left text-black text-sm hover:bg-gray-100"
                        >
                            {lang.label}
                        </button>
                    ))}
                </div>
            )}
        </div>
    );
};

export default LanguageSelector;