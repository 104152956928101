import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../core/user';

import {
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    Box,
    Container,
    Tooltip,
    IconButton,
    CircularProgress,
} from '@mui/material';

import {
    CloudOutlined,
    KeyOutlined,
    VpnKeyOutlined,
    PublicOutlined,
    InfoOutlined,
    DeleteOutline,
    ContentCopyOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { fetchCreateAzureCredential } from '../../services/csp/azureServices';

import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 500,
    margin: '40px auto',
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
    borderRadius: 16,
    background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
}));

const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const InputContainer = styled(Box)({
    position: 'relative',
});

const RegisterAzure = ({ onSuccess, onClose }) => {
    const { t } = useTranslation(['project', 'common']);

    const { userState, setUserState } = useContext(UserContext);

    const [formData, setFormData] = useState({
        azure_tenant_id: '',
        azure_client_id: '',
        azure_client_secret: '',
        azure_subscription_id: '',
    });

    const [showSecret, setShowSecret] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');
        setIsSuccess(false);

        try {
            const response = await fetchCreateAzureCredential(formData, userState.com_id);

            if (response.status === 200) {
                setIsSuccess(true);
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.detail || "AWS Credential validation failed.");
            }
        } catch (error) {
            setErrorMessage("Network error or server is unreachable.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    return (
        <Container maxWidth="sm">
            <StyledCard>
                <CardContent sx={{ p: 4 }}>
                    <Box display="flex" alignItems="center" mb={3} sx={{ justifyContent: 'center' }}>
                        <CloudOutlined
                            sx={{ fontSize: 40, color: 'primary.main', mr: 2 }}
                        />
                        <Typography variant="h4" fontWeight="600">
                            Azure {t("common:Connection")}
                        </Typography>
                    </Box>
                    {isSuccess ? (
                        <Typography variant="h5" sx={{ color: 'green', textAlign: 'center' }}>
                            ✅ {t("RegisterSuccess")}!
                        </Typography>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <InputContainer>
                                <TextField
                                    fullWidth
                                    label="Tenant ID"
                                    variant="outlined"
                                    value={formData.azure_tenant_id}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            azure_tenant_id: e.target.value,
                                        })
                                    }
                                    autoComplete='off'
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#007FFF',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#007FFF',
                                            },
                                        },
                                    }}
                                    
                                    InputProps={{
                                        startAdornment: (
                                            <KeyOutlined
                                                sx={{
                                                    mr: 1,
                                                    color: 'text.secondary',
                                                }}
                                            />
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                onClick={() =>
                                                    handleCopyToClipboard(
                                                        formData.azure_tenant_id,
                                                    )
                                                }
                                            >
                                                <ContentCopyOutlined />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <TextField
                                    fullWidth
                                    label="Client ID"
                                    variant="outlined"
                                    value={formData.azure_client_id}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            azure_client_id: e.target.value,
                                        })
                                    }
                                    autoComplete='off'
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#007FFF',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#007FFF',
                                            },
                                        },
                                    }}
                                    
                                    InputProps={{
                                        startAdornment: (
                                            <KeyOutlined
                                                sx={{
                                                    mr: 1,
                                                    color: 'text.secondary',
                                                }}
                                            />
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                onClick={() =>
                                                    handleCopyToClipboard(
                                                        formData.azure_client_id,
                                                    )
                                                }
                                            >
                                                <ContentCopyOutlined />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <TextField
                                    fullWidth
                                    label="Client Secret"
                                    variant="outlined"
                                    type={showSecret ? 'text' : 'password'}
                                    value={formData.azure_client_secret}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            azure_client_secret: e.target.value,
                                        })
                                    }
                                    autoComplete='off'
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#007FFF',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#007FFF',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <VpnKeyOutlined
                                                sx={{
                                                    mr: 1,
                                                    color: 'text.secondary',
                                                }}
                                            />
                                        ),
                                        endAdornment: (
                                            <Box>
                                                <IconButton
                                                    onClick={() =>
                                                        setShowSecret(!showSecret)
                                                    }
                                                >
                                                    {showSecret ? (
                                                        <DeleteOutline />
                                                    ) : (
                                                        <InfoOutlined />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        ),
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <TextField
                                    fullWidth
                                    label="Subscription ID"
                                    variant="outlined"
                                    value={formData.azure_subscription_id}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            azure_subscription_id: e.target.value,
                                        })
                                    }
                                    autoComplete='off'
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#007FFF',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#007FFF',
                                            },
                                        },
                                    }}
                                    
                                    InputProps={{
                                        startAdornment: (
                                            <KeyOutlined
                                                sx={{
                                                    mr: 1,
                                                    color: 'text.secondary',
                                                }}
                                            />
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                onClick={() =>
                                                    handleCopyToClipboard(
                                                        formData.azure_subscription_id,
                                                    )
                                                }
                                            >
                                                <ContentCopyOutlined />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </InputContainer>
                            
                            {errorMessage && (
                                <Typography 
                                    variant="body2" 
                                    sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                >
                                    ❌ {errorMessage}
                                </Typography>
                            )}
                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{
                                    mt: 2,
                                    height: 56,
                                    background:
                                        'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
                                    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)',
                                    textTransform: 'none',
                                    '&:hover': {
                                        background:
                                            'linear-gradient(45deg, #1976D2 30%, #00BCD4 90%)',
                                    },
                                }}
                                startIcon={<CloudOutlined />}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : t("RegisterAzureConnection")}
                            </Button>
                        </Form>
                    )}
                </CardContent>
            </StyledCard>
        </Container>
    );
};

export default RegisterAzure;
