import React, {useState} from 'react';

import { 
    Typography,
} from '@mui/material';

import Logo from '../../static/img/csrc_logo.png';

const HeaderIcon = (props) => {
    return (
        <div
            className="flex flex-row space-x-2 items-center cursor-pointer"
            onClick={props.onClickLogo}
        >
            <img 
                src={Logo}
                className="header-icon scale-75"
            />

            <Typography
                    color="inherit"
                    noWrap
                    sx={{ 
                        flexGrow: 1,
                        color: props.color,
                        fontSize: '18px',
                    }}
                >
                    PNU ZeroTrust Cloud Security Research Center
            </Typography>
        </div>
    );
};

export { HeaderIcon, };
