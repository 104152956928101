import React, { useState } from 'react';

import { 
    Box, 
    Container, 
    Grid, 
    Paper, 
    Typography, 
    Link 
} from '@mui/material';

const CloudInfo = () => {
    return (
        <div className='flex w-full flex-col'>
            <Typography variant="h5">
                Cloud Information
            </Typography>
            <Grid container spacing={3}>
                <Grid item xs={12} md={9} lg={10}>
                    <div style={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
                        <div className="flex flex-row items-center">
                            <Typography variant="h6">
                                Company: 
                            </Typography>
                            <Typography variant="body1">
                                s3lab
                            </Typography>
                        </div>
                        <div className="flex flex-row items-center">
                            <Typography variant="h6">
                                Connected Clouds:
                            </Typography>
                            <Typography variant="body1">
                                AWS, Azure
                            </Typography>
                        </div>
                    </div>
                </Grid>
                <Grid item xs={12} md={3} lg={2}>
                    <div style={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default CloudInfo;
