import React from "react";

import {
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem
} from "@mui/material";

import aws_logo from '../../../assets/images/AWS_Logo.svg';
import azure_logo from '../../../assets/images/Azure_Logo.svg';
import openstack_logo from '../../../assets/images/Openstack_Logo.svg';

import { useTranslation } from "react-i18next";

const InstanceInfo = ({instances, selectedInstance, handleInstanceChange}) => {
    const { t } = useTranslation(["project", "common"]);



    const getCspLogo = (csp) => {
        switch (csp) {
          case 'AWS':
            return aws_logo;
          case 'Azure':
            return azure_logo;
          case 'OpenStack':
            return openstack_logo;
          default:
            return null;
        }
    };

    const getCspFullName = (csp) => {
        switch (csp) {
          case 'AWS':
            return t("amazonWebServices");
          case 'Azure':
            return t("microsoftAzure");
          case 'OpenStack':
            return 'OpenStack';
          default:
            return null;
        }
    };

    return (
        <div>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                {/* "Name:" 텍스트 */}
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                    {t("instance")}:
                </Typography>

                {/* Select 박스 (기본 값으로 instance_name 표시) */}
                <FormControl sx={{ minWidth: 200 }}>
                    <Select
                    value={selectedInstance.instance_id}
                    onChange={handleInstanceChange}
                    displayEmpty
                    renderValue={() => selectedInstance.instance_name} // 🔥 선택된 인스턴스 이름 표시
                    sx={{
                        height: "2.5rem", // 🔥 높이 조정
                        minWidth: "12rem", // 🔥 너비 조정
                        "& .MuiSelect-select": {
                        padding: "8px 32px 8px 8px", // 🔥 선택 영역 패딩 조정
                        fontSize: "1.25rem", // 🔥 폰트 크기 조정
                        fontWeight: "bold", // 🔥 굵기 조정
                        },
                        "& .MuiSelect-icon": {
                        fontSize: "1.5rem", // 🔥 화살표 크기 조정
                        },
                    }}
                    >
                        {instances.map((inst) => (
                            <MenuItem key={inst.instance_id} value={inst.instance_id}>
                            {inst.instance_name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </Box>

            <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
                (ID: {selectedInstance.instance_id})
            </Typography>

            {/* 🔹 CSP + 로고 */}
            <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                CSP: {getCspFullName(selectedInstance.csp_name)}
                </Typography>
                {getCspLogo(selectedInstance.csp_name) && (
                <img
                    src={getCspLogo(selectedInstance.csp_name)}
                    alt={`${selectedInstance.csp_name} Logo`}
                    style={{ height: 20 }}
                />
                )}
            </Box>

            {/* 🔹 상세 정보 */}
            <Box sx={{ pl: 1 }}>
                <Typography>- {t("instanceType")}: {selectedInstance.instance_type}</Typography>
                <Typography>- {t("instanceStatus")}: {selectedInstance.instance_state}</Typography>
                <Typography>- {t("instanceOS")}: {selectedInstance.instance_os}</Typography>
                <Typography>- {t("publicIP")}: {selectedInstance.public_ip}</Typography>
                <Typography>- {t("vpnIP")}: {selectedInstance.vpn_ip}</Typography>
                {/* <Typography>- VPC ID: {selectedInstance.vpc_id}</Typography> */}
                <Typography>
                - Group Name:{" "}
                {selectedInstance.security_groups
                    ? JSON.parse(selectedInstance.security_groups)
                        .map((group) => group.GroupName)
                        .join(", ")
                    : "N/A"}
                </Typography>
                <Typography>- {t("description")}: {selectedInstance.description}</Typography>
            </Box>
        </div>
    )
};

export default InstanceInfo;