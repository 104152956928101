import React, { useState } from 'react';
import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Grid,
    Link,
    TextField,
    InputAdornment
} from '@mui/material';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PasswordIcon from '@mui/icons-material/Password';

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';

const SignInForm = ({ handleSignIn, formErrors }) => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const { t } = useTranslation('authentication');
    const handleNavigation = useNavigationForLang();

    const onSubmit = (e) => {
        e.preventDefault();
        handleSignIn(email, password);
    };

    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    };

    return (
        <Box component="form" onSubmit={onSubmit} sx={{ mt: 1 }}>
            <TextField
                margin="normal"
                required
                fullWidth
                label={t("id")}
                name="email"
                autoComplete="email"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                error={!!formErrors.email}
                helperText={formErrors.email}
                onKeyDown={handleKeyDown}
                autoFocus
            />
            <TextField
                margin="normal"
                required
                fullWidth
                label={t("password")}
                type="password"
                id="password"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PasswordIcon />
                        </InputAdornment>
                    ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                error={!!formErrors.password}
                helperText={formErrors.password}
                onKeyDown={handleKeyDown}
            />
            <FormControlLabel
                control={<Checkbox value="remember" color="primary" />}
                label={t("staySignedIn")}
            />
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
            >
                {t('signin')}
            </Button>
            <Grid container>
                <Grid item xs>
                    <Button
                        variant="text"
                        onClick={() => handleNavigation('/forgot-password')}
                        sx={{ textTransform: "none", textDecoration: "underline" }}
                    >
                        {t('forgotPassword')}
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        variant="text"
                        onClick={() => handleNavigation('/SignUp')}
                        sx={{ textTransform: "none", textDecoration: "underline" }}
                    >
                        {t('signup')}
                    </Button>
                </Grid>
            </Grid>
        </Box>
    );
};

export default SignInForm;