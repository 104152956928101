import React from 'react';
import { Outlet } from 'react-router-dom';

const MainLayout = () => {
    return (
        <div className="w-full h-full min-h-screen">
            <div 
                className='flex w-full flex-grow flex-col'
                style={{
                    backgroundColor: "rgba(255,255,255,255)",
                    flexGrow: 1,
                    height: "100vh",
                  }}
            >
                <Outlet />
            </div>
        </div>
    );
};

export default MainLayout;
