import React from 'react';

import {
    Avatar,

} from '@mui/material';

import aws_logo from '../../assets/images/AWS_Logo.svg';
import azure_logo from '../../assets/images/Azure_Logo.svg';
import openstack_logo from '../../assets/images/Openstack_Logo.svg';
import { border, borderRadius } from '@mui/system';

const CircleLogo = ({ csp, width=40, height=40}) => {
    const logo = csp === 'AWS' ? aws_logo :
                 csp === 'Azure' ? azure_logo : 
                 openstack_logo;

    // if sx null
    const sx = { 
        width: width, 
        height: height, 
        backgroundColor: 'transparent',
        outline: "0.5px solid #000",
        // p: 0.5
    }

    let image_style = {};

    if (csp === "AWS"){
        image_style = {
            width: "90%",
            height: "90%",
            objectFit: "contain",
            borderRadius: "50%"
        };
    }
    else if (csp === "Azure"){
        image_style = {
            width: "90%",
            height: "90%",
            objectFit: "contain",
            borderRadius: "50%"
        };
    }

    return (
        <Avatar
            sx={sx}
        >
            <img src={logo} style={image_style} />
        </Avatar>
    );
}

export default CircleLogo;