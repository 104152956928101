import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { styled, useTheme } from '@mui/material/styles';
import {
    Box,
    List,
    ListItem,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Divider,
    Toolbar,
    IconButton,
    Drawer as MuiDrawer,
} from '@mui/material';

import HomeIcon from '@mui/icons-material/Home';
import ComputerIcon from '@mui/icons-material/Computer';
import TableViewIcon from '@mui/icons-material/TableView';
import PersonSearchIcon from '@mui/icons-material/PersonSearch';
import DashboardIcon from '@mui/icons-material/Dashboard';
import InfoIcon from '@mui/icons-material/Info';

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';

// 사이드바 너비 설정
const drawerWidth = 240;

// ✅ Drawer 스타일 확장 (열림/닫힘 상태 반영)
const openedMixin = (theme) => ({
    width: drawerWidth,
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
    }),
    overflowX: 'hidden',
});

const closedMixin = (theme) => ({
    transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing(9), // 닫힌 상태의 너비
});

// ✅ Drawer 스타일 적용
const Drawer = styled(MuiDrawer)(({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open ? openedMixin(theme) : closedMixin(theme)),
    '& .MuiDrawer-paper': open ? openedMixin(theme) : closedMixin(theme),
}));

const VerticalNavbar = ({ open }) => {
    const { t } = useTranslation('project');
    const handleNavigation = useNavigationForLang();
    const navigate = useNavigate();
    const location = useLocation();
    const theme = useTheme();
    // const [open, setOpen] = useState(true);

    const menuItems = [
        { text: t("dashboard"), icon: <DashboardIcon />, path: "/Dashboard" },
        { text: t("instances"), icon: <ComputerIcon />, path: "/Instance" },
        { text: t("accessControlList"), icon: <TableViewIcon />, path: "/ACL" },
        { text: "UEBA", icon: <PersonSearchIcon />, path: "/UEBA" },
        { text: t("What is CASB"), icon: <InfoIcon />, path: "/what-is-casb" },
    ];

    return (
        <div className="flex"
            style={{
                backgroundColor: "#f1f1f4",
            }}
        >
            {/* ✅ 사이드바 */}
            <aside
                style={{
                    width: open ? "240px" : "64px", // 🔥 접히고 펼쳐지는 사이즈 변경
                    transition: "width 0.3s ease-in-out", // 🔥 부드러운 애니메이션
                    borderRight: "1px solid gray",
                    overflow: "hidden",
                    minHeight: "100vh",
                }}
            >
                <List>
                    {menuItems.map((item, index) => (
                        <React.Fragment key={index}> {/* ✅ Fragment로 감싸기 */}
                            <ListItem disablePadding sx={{ display: 'block' }}>
                                <ListItemButton
                                    onClick={() => handleNavigation(item.path)}
                                    selected={location.pathname === item.path} // 현재 선택된 메뉴 스타일 적용
                                    sx={{
                                        minHeight: 48,
                                        justifyContent: open ? 'initial' : 'center',
                                        px: 2.5,
                                        '&.Mui-selected': {
                                            backgroundColor: "white",
                                            color: 'black',
                                            '&:hover': {
                                                backgroundColor: "#F2F1F1",
                                            },
                                        },
                                    }}
                                >
                                    <ListItemIcon
                                        sx={{
                                            minWidth: 0,
                                            justifyContent: 'center',
                                            mr: open ? 3 : 'auto',
                                        }}
                                    >
                                        {item.icon}
                                    </ListItemIcon>
                                    <ListItemText
                                        primary={item.text}
                                        sx={{
                                            display: open ? 'block' : 'none',
                                            whiteSpace: 'nowrap',
                                            textOverflow: 'ellipsis',
                                            opacity: open ? 1 : 0,
                                        }}
                                    />
                                </ListItemButton>
                            </ListItem>
                            {(index === 0 || index === 3) && <Divider />} {/* ✅ 오류 없이 렌더링 가능 */}
                        </React.Fragment>
                    ))}
                </List>
            </aside>
        </div>
    );
};

export default VerticalNavbar;

