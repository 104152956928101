import AppConfig, {getCSP_URL} from '../core/config';
import { fetchRefresh } from './AuthServices';

// Fetch ACLs by uid
const fetchInstances = async () => {
    const url = `${AppConfig.api_base_url}/instance/get-all`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchInstanceByAwsId = async (aws_id) => {
    const url = `${AppConfig.api_base_url}/instance/get-by-aws-id/${aws_id}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}



const fetchInstanceByCSPAndIID = async (csp_name, iid) => {
    const csp_url = getCSP_URL({csp: csp_name});
    console.log(csp_url);

    const url = `${csp_url}/instance/get/${iid}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchAWSInstanceMetrics = async (iid) => {
    const url = `${AppConfig.aws_base_url}/instance/metric/${iid}`;

    try {
        let response = await fetch(url, {
            method: 'GET',
            headers: {
                Accept: 'application/json',
            },
            credentials: 'include',
        });

        if (response.status === 401 || response.status === 422) {
            console.warn("Unauthorized or session expired. Attempting token refresh...");

            const refreshResponse = await fetchRefresh();
            if (refreshResponse.status === 200) {
                console.log("Token refreshed successfully. Retrying API request...");

                response = await fetch(url, {
                    method: 'GET',
                    headers: {
                        Accept: 'application/json',
                    },
                    credentials: 'include',
                });
            } else {
                console.error("Token refresh failed. Returning original response.");
                return response;
            }
        }

        return response;
    } catch (error) {
        console.error("Error fetching instance metrics:", error);
        throw error;
    }
};

const syncInstances = async (uid) => {
    // print(uid);
    const url = `${AppConfig.api_base_url}/instance/sync_instances/${uid}`;  // URL 수정됨!

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.ok) {
        console.log("✅ Instances synced successfully");
        // return response;
        return fetchInstances(); // Sync 후 최신 데이터 다시 불러오기
    } else {
        console.error("❌ Failed to sync instances");
        return response;
    }
};

const fetchInstanceProfiling = async (iid, topK) => {
    const url = `${AppConfig.api_base_url}/instance/get/${iid}/${topK}/profiling`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchInstanceAlert = async (iid, count) => {
    const url = `${AppConfig.api_base_url}/instance/get/${iid}/instance-alerts/${count}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}


const fetchInstanceSyscallAnomalyCount = async (iid, timeFilter) => {
    const url = `${AppConfig.api_base_url}/instance/get/${iid}/syscall-anomaly-count/${timeFilter}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchInstanceSyscallAnomalyAverageScores = async (iid, timeFilter) => {
    const url = `${AppConfig.api_base_url}/instance/get/${iid}/average-syscall-anomaly-scores/${timeFilter}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const fetchInstanceSyscallAnomalyMaxScores = async (iid, timeFilter) => {
    const url = `${AppConfig.api_base_url}/instance/get/${iid}/max-syscall-anomaly-scores/${timeFilter}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const updateInstanceProfilingStatus = async (iid, isProfiling) => {
    const url = `${AppConfig.api_base_url}/instance/update-profiling-status`;

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            iid: iid,
            isProfiling: isProfiling,
        }),
    });

    if (response.ok) {
        console.log("✅ Instance profiling status updated successfully");
        return response;
    } else {
        console.error("❌ Failed to update instance profiling status");
        return response;
    }
}

const fetchInstanceThreshold = async (iid) => {
    const url = `${AppConfig.api_base_url}/instance/get-threshold/${iid}`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

const updateInstanceThreshold = async (iid, threshold) => {
    const url = `${AppConfig.api_base_url}/instance/update-threshold`;

    console.log("Updating instance threshold:", iid, threshold);

    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify({
            iid: iid,
            threshold: threshold,
        }),
    });

    if (response.ok) {
        console.log("✅ Instance threshold updated successfully");
        return response;
    } else {
        console.error("❌ Failed to update instance threshold");
        return response;
    }
}

export { fetchInstances, fetchInstanceByCSPAndIID, syncInstances, fetchInstanceProfiling, 
    fetchAWSInstanceMetrics, fetchInstanceByAwsId, fetchInstanceAlert,
    fetchInstanceSyscallAnomalyCount, fetchInstanceSyscallAnomalyAverageScores, fetchInstanceSyscallAnomalyMaxScores,
    updateInstanceProfilingStatus, fetchInstanceThreshold, updateInstanceThreshold
};