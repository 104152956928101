import React, { useContext, useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';

import {
    Button,
    Dialog,
    Typography,
    Divider,
    CircularProgress,
    Avatar,
    Grid,
    Paper,
} from '@mui/material';

import { UserContext } from '../../core/user';

import { fetchInitMyPageInfo } from '../../services/myPage/MyPageServices';
import { fetchSyncAWSInstances } from '../../services/csp/awsServices';
import { fetchSyncAzureInstances } from '../../services/csp/azureServices';

import RegisterCSP from '../csp/RegisterCSP';
import RegisterAWS from '../csp/RegisterAWS';
import RegisterAzure from '../csp/RegisterAzure';
import RegisterOpenStack from '../csp/RegisterOpenStack';

import aws_logo from '../../assets/images/AWS_Logo.svg';
import azure_logo from '../../assets/images/Azure_Logo.svg';
import openstack_logo from '../../assets/images/Openstack_Logo.svg';

import theme from '../../theme';

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';

const CSP_COMPONENTS ={
    'AWS': RegisterAWS,
    'Azure': RegisterAzure,
    'Openstack': RegisterOpenStack,
}

const MyPage = () => {
    const navigate = useNavigate();

    const { t } = useTranslation(["project", "authentication", "common"]);
    const handleNavigation = useNavigationForLang();

    const { userState, setUserState } = useContext(UserContext);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedCSP, setSelectedCSP] = useState(null);

    const [isCloudSyncLoading, setIsCloudSyncLoading] = useState(false);
    const [isCloudSyncErrorMsg, setIsCloudSyncErrorMsg] = useState('');
    const [isCloudSyncSuccess, setIsCloudSyncSuccess] = useState(false);

    const SelectedCSPComponent = CSP_COMPONENTS[selectedCSP];

    const [company, setCompany] = useState('');
    const [csp, setCSP] = useState('');

    useEffect(() => {    
        fetchData();
    }, []);
    
    const fetchData = async () => {
        try {
            const response = await fetchInitMyPageInfo(userState.com_id);
            
            const data = await response.json();
            
            setCompany(data.company);
            setCSP(data.csp);
            console.log('data', data);
        } catch (error) {
            console.error('error', error);
        }
    };

    const handleClickOpen = () => {
        setSelectedCSP(null);
        setDialogOpen(true);
    };

    const handleClickSync = async () => {
        setIsCloudSyncLoading(true);
        setIsCloudSyncErrorMsg('');
        setIsCloudSyncSuccess(false);

        try{
            const response = await fetchSyncAWSInstances(userState.com_id); 
            const azure_response = await fetchSyncAzureInstances();


            if (response.status === 200) {
                setIsCloudSyncSuccess(true);
            } else {
                const errorData = await response.json();
                setIsCloudSyncErrorMsg(errorData.message);
            }
        } catch (error) {
            setIsCloudSyncErrorMsg('An error occurred while syncing cloud');
        } finally {
            setIsCloudSyncLoading(false);
        }
    };

    const handleClose = () => {
        setDialogOpen(false);
        fetchData();
    };

    const returnConnectedCloud = () => {
        const cloudServices = [
            { key: "aws", logo: aws_logo, name: "AWS" },
            { key: "azure", logo: azure_logo, name: "Azure" },
            { key: "openstack", logo: openstack_logo, name: "OpenStack" },
        ];
    
        return cloudServices
            .filter(service => csp[service.key])  // ✅ csp에 해당 키가 있는 것만 필터링
            .map(service => (
                <Avatar
                    key={service.key}
                    sx={{
                        ...theme.typography.smallAvatar,
                        backgroundColor: 'transparent',
                        marginRight: '10px',
                        border: '1px solid dark',
                    }}
                >
                    <img src={service.logo} alt={service.name} />
                </Avatar>
            ));
    };
    

    return (
        <div className="flex w-full">
            <Dialog
                open={dialogOpen}
                onClose={handleClose}
                maxWidth="xl"
                // fullWidth={true}
            >
                {selectedCSP ? (
                     <SelectedCSPComponent onBack={() => setSelectedCSP(null)} />
                ) : (
                    <RegisterCSP onSelectCSP={setSelectedCSP} />
                )}
            </Dialog>
            <Grid container direction="column" spacing={2} sx={{ width: "100%" }}>
                <Grid container item spacing={2}>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <Paper sx={{ p: 3, flexGrow: 1, display: "flex", flexDirection: "column" }}>
                            <div className='mb-4'>
                                <Typography
                                    variant="h5"
                                    sx={{
                                        fontFamily: 'Roboto-Regular',
                                        fontWeight: 'bold',
                                    }}
                                >
                                    {t("myInformation")}
                                </Typography>
                            </div>
                            <Divider />
                            <div className="flex flex-col justify-start border-solid border-gray-400 border-2 p-5 flex-grow">
                                <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} className="w-[150px]">
                                        {t("user", { ns: "authentication" })}
                                    </Typography>
                                    <div className="px-4">
                                        <Typography variant="outlined" size="small">
                                            {userState.username}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} className="w-[150px]">
                                        {t("company", { ns: "authentication" })}
                                    </Typography>
                                    <div className="px-4">
                                        <Typography variant="outlined" size="small">
                                            {company.company_name}
                                        </Typography>
                                    </div>
                                </div>
                                <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                                    <div className="w-[150px]">
                                        <Typography variant="h6" sx={{ fontWeight: 'bold' }}>
                                            {t("Email", { ns: "authentication" })}
                                        </Typography>
                                    </div>
                                    <div className="px-4 w-[300px]">
                                        <Typography variant="outlined" size="small">
                                            {userState.email}
                                        </Typography>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} md={6} sx={{ display: "flex", flexDirection: "column", height: "100%" }}>
                        <Paper sx={{ p: 3, flexGrow: 1, display: "flex", flexDirection: "column" }}>
                            <div className="flex flex-row justify-between items-center mb-4">
                                <Typography variant="h5" sx={{ fontFamily: 'Roboto-Regular', fontWeight: 'bold', lineHeight: '32.1px' }}>
                                    {t("csp") + " " + t("information", { ns: "common" })}
                                </Typography>
                                <Button
                                    className='ml-auto'
                                    variant="contained"
                                    color="primary"
                                    sx={{ fontSize: "18px", height: "32.1px", alignItems: "center" }}
                                    onClick={handleClickOpen}
                                >
                                    {t("addCSP")}
                                </Button>
                            </div>
                            <Divider />
                            <div className="flex flex-col justify-center border-solid border-gray-400 border-2 p-5 flex-grow">
                                <div className="flex flex-row justify-start items-center p-2 divide-x-2">
                                    <Typography variant="h6" sx={{ fontWeight: 'bold' }} className="w-[200px]">
                                        {t("connectedCSP")}
                                    </Typography>
                                    <Button
                                        className='ml-auto'
                                        variant="contained"
                                        color="primary"
                                        sx={{ fontSize: "18px", height: "32.1px", alignItems: "center" }}
                                        onClick={handleClickSync}
                                    >
                                        {isCloudSyncLoading ? <CircularProgress size={24} color="inherit" /> : t("Sync")}
                                    </Button>
                                </div>
                                <div className="flex flex-row">
                                    {returnConnectedCloud()}
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                </Grid>
            </Grid>
        </div>
    );
};

export default MyPage;
