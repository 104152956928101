import React, { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import { 
  Box, 
  Typography,
  Paper,
  Grid,
  TextField,
  Button,
  OutlinedInput,
  FormControl
} from "@mui/material";

import InstanceResourceMonitoring from '../instance/instancePageComponent/InstanceResourceMonitoring';
import UserInfo from "./component/UserInfo";
import Alert from "./component/Alert";
import Profile from "./component/Profiling";
import BehaviorAnalytics from "./component/BehaviorAnalytics";
import AnomalyCountBar from "../instance/instancePageComponent/AnomalyCountBar";

import { 
  fetchInstanceByCSPAndIID,
  fetchInstances,
  updateInstanceProfilingStatus,
  updateInstanceThreshold
} from '../../services/InstanceServices';

import { useTranslation } from "react-i18next";
import { useNavigationForLang } from "../../i18n";

const UEBA = () => {
//   const { csp_name, iid } = useParams();
    const [csp_name, SetCsp_name] = useState("AWS");
    const [iid, SetIid] = useState(1);
    const [instances, setInstances] = useState([]); // 🔥 모든 인스턴스 목록
    const [selectedInstance, setSelectedInstance] = useState(null); // 🔥 현재 선택된 인스턴스 정보
    const [threshold, setThreshold] = useState(null);
    const [inputValue, setInputValue] = useState("");

    const { t } = useTranslation("project");
    const handleNavigation = useNavigationForLang();

    useEffect(() => {
        fetchInstancesList();
    }, []);

    useEffect(() => {
        if (iid) {
        fetchInstanceInfo(csp_name, iid);
        }
    }, [csp_name, iid]);

    const fetchInstancesList = async () => {
        try {
        const response = await fetchInstances(); // 모든 인스턴스 가져오기
        const data = await response.json();
        setInstances(data);
        } catch (error) {
        console.error("Error fetching instances:", error);
        }
    };

        // 🔥 선택한 인스턴스 정보 가져오기
    const fetchInstanceInfo = async (csp_name, iid) => {
        try {
            const response = await fetchInstanceByCSPAndIID(csp_name, iid);
            const data = await response.json();
            
            setSelectedInstance(data);
        } catch (error) {
            console.error("Error fetching instance:", error);
        }
    };

    // 🔥 사용자가 Select에서 인스턴스를 변경하면 업데이트
    const handleInstanceChange = (event) => {
        const instance_id = event.target.value;
        const instance = instances.find((instance) => instance.instance_id === instance_id);
        console.log(instance);
        handleNavigation(`/Instance/${instance.csp_name}/${instance.iid}`);
    };

    const handleIstanceProfilingToggle = async (event) => {
        const response = await updateInstanceProfilingStatus(selectedInstance.iid, event.target.checked);
        if (response.status === 200) {
        const data = await response.json();
        setSelectedInstance(data);
        }
    };

    const updateInstanceThresholdHandler = async (threshold) => {
        const response = await updateInstanceThreshold(selectedInstance.iid, threshold);
        if (response.status === 200) {
        const data = await response.json();
        selectedInstance.threshold = data
        }
        return response;
    }

    const onSubmit = async (e) => {
        if (inputValue.trim() === "") return;

        const response = await updateInstanceThresholdHandler(inputValue);
        if (response.ok) {
        setThreshold(inputValue);
        setInputValue("");
        }
    };

    return (
        <div className="flex w-full">
        {selectedInstance ? (
            <Grid container direction="column" spacing={2} sx={{ width: "100%" }}>
            <Grid container item spacing={2}>
                <Grid item xs={12} md={3}>
                    <Paper sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
                        <UserInfo 
                            instances={instances} 
                            selectedInstance={selectedInstance} 
                            handleInstanceChange={handleInstanceChange}
                        />
                    </Paper>
                </Grid>

                <Grid item xs={12} md={9}>
                    <Paper sx={{ p: 3, height: "100%", width: "100%", display: "flex", flexDirection: "column" }}>
                        <Typography variant="h5" sx={{marginBottom:"10px"}}>{t("User Entity Behavior Analysis")}</Typography>
                        <Grid container item spacing={2}>
                            <Grid item sx={12} md={6}>
                                <BehaviorAnalytics 
                                    iid={selectedInstance.iid} 
                                    type={"max"} 
                                    threshold={threshold}
                                    setThreshold={setThreshold}
                                />
                            </Grid>
                            <Grid item sx={12} md={6}>
                                <BehaviorAnalytics 
                                    iid={selectedInstance.iid} 
                                    type={"mean"} 
                                    threshold={threshold}
                                    setThreshold={setThreshold}
                                />
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>
            </Grid>

            <Grid container item spacing={2}>
                <Grid item xs={12} md={3}>
                <Paper sx={{ py:3, height: "100%", display: "flex", flexDirection: "column" }}>
                    <Alert 
                        iid={iid}
                    />
                </Paper>
                </Grid>

                <Grid item xs={12} md={9}>
                <Paper sx={{ p: 3, height: "100%", display: "flex", flexDirection: "column" }}>
                    <Profile 
                        iid={selectedInstance.iid} 
                        selectedInstance={selectedInstance} 
                        handleIstanceProfilingToggle={handleIstanceProfilingToggle}
                    />
                </Paper>
                </Grid>
            </Grid>

         
            </Grid>
        ) : (
            <Typography variant="h5">Loading...</Typography>
        )}
        </div>
    );
};

export default UEBA;