import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import LanguageDetector from 'i18next-browser-languagedetector';

import commonn from './locales/common.json';
import home from './locales/home.json';
import authentication from './locales/authentication.json';
import project from './locales/project.json';
import projectHome from './locales/projectHome.json';

import { useCallback } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";

const supportedLanguages = ['en', 'ko'];

const resources = {
    en:{
        common: commonn.en,
        home: home.en,
        authentication: authentication.en,
        project: project.en,
        projectHome: projectHome.en
    },
    ko: {
        common: commonn.ko,
        home: home.ko,
        authentication: authentication.ko,
        project: project.ko,
        projectHome: projectHome.ko
    }
}

const getLanguageFromPath = () => {
  const pathLang = window.location.pathname.split('/')[1];
  if (supportedLanguages.includes(pathLang)) {
    return pathLang;
  }
  return "en";
};

const useNavigationForLang = () => {
  const navigate = useNavigate();
  const params = useParams();
  const location = useLocation();

  const handleNavigation = useCallback(
      (path) => {
          const { lang } = params;
          const currentLang = lang || "en"; // ✅ 기본 언어 설정

          const newPath = currentLang === "en" ? path : `/${currentLang}${path}`;

          navigate(newPath);
      },
      [navigate, params, location]
  );

  return handleNavigation;
};

i18n
  .use(LanguageDetector) 
  .use(initReactI18next)
  .init({
    resources,
    lng: getLanguageFromPath(), // 기본 언어 설정
    fallbackLng: "en", // 사용자가 설정하지 않은 경우 기본 언어
    ns: ["common", "home", "authentication", "project", "projectHome"],
    defaultNS: "common",
    interpolation: { escapeValue: false },
    detection: {
      order: ['path', 'localStorage', 'navigator'], // 로컬스토리지에서 언어를 찾고, 없으면 브라우저 언어를 사용
      caches: ['localStorage'], // 언어를 localStorage에 캐시
      lookupFromPathIndex: 0, 
      lookupQuerystring: 'lang',
    },
  });

export { useNavigationForLang, i18n };
