import React, { useState, useEffect } from 'react';

import { 
  Box, 
  Typography,
  Select,
  MenuItem,
  Switch
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';

import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  CartesianGrid, 
  ResponsiveContainer 
} from 'recharts';

import { fetchInstanceProfiling } from '../../../services/InstanceServices';

import { useTranslation } from 'react-i18next';


const paginationModel = { page: 0, pageSize: 5 };

const fileAccessData = [
    { file_path: "/etc/passwd", user: "root", access_type: "read", count: 62 },
    { file_path: "/etc/hostname", user: "system", access_type: "read", count: 40 },
    { file_path: "/etc/hosts", user: "network", access_type: "write", count: 32 },
    { file_path: "/var/lib/mysql/mysql-bin.000001", user: "dbadmin", access_type: "write", count: 31 },
    { file_path: "/etc/shadow", user: "root", access_type: "read", count: 30 },
    { file_path: "/usr/local/bin/deploy.sh", user: "devops", access_type: "execute", count: 30 },
    { file_path: "/root/.bashrc", user: "root", access_type: "read", count: 29 },
    { file_path: "/dev/sda1", user: "system", access_type: "read", count: 28 },
    { file_path: "/etc/nginx/nginx.conf", user: "webadmin", access_type: "write", count: 27 },
    { file_path: "/var/log/syslog", user: "admin", access_type: "write", count: 25 },
    { file_path: "/var/www/html/index.php", user: "webadmin", access_type: "write", count: 23 },
    { file_path: "/usr/bin/python3", user: "system", access_type: "execute", count: 22 },
    { file_path: "/etc/crontab", user: "cron", access_type: "write", count: 21 },
    { file_path: "/var/log/auth.log", user: "admin", access_type: "write", count: 20 },
    { file_path: "/home/user/.bash_history", user: "user", access_type: "read", count: 19 },
    { file_path: "/opt/software/installer.bin", user: "admin", access_type: "execute", count: 18 },
    { file_path: "/etc/network/interfaces", user: "network", access_type: "write", count: 17 },
    { file_path: "/var/log/dpkg.log", user: "admin", access_type: "write", count: 16 },
    { file_path: "/home/user/scripts/malware.sh", user: "user", access_type: "execute", count: 15 },
    { file_path: "/home/user/downloads/movie.mp4", user: "user", access_type: "read", count: 14 },
    { file_path: "/mnt/usb/backup.tar.gz", user: "user", access_type: "write", count: 13 },
    { file_path: "/root/.ssh/id_rsa", user: "root", access_type: "read", count: 12 },
    { file_path: "/usr/local/etc/config.yaml", user: "system", access_type: "read", count: 11 },
    { file_path: "/home/user/documents/report.pdf", user: "user", access_type: "read", count: 10 },
    { file_path: "/usr/share/fonts/truetype/custom.ttf", user: "system", access_type: "read", count: 9 },
    { file_path: "/home/user/documents/secret.txt", user: "user", access_type: "write", count: 8 },
    { file_path: "/home/user/temp/.cache", user: "user", access_type: "delete", count: 7 },
    { file_path: "/root/install.log", user: "root", access_type: "write", count: 6 },
    { file_path: "/tmp/tmpfile123", user: "guest", access_type: "delete", count: 2 },
    { file_path: "/tmp/session.lock", user: "guest", access_type: "delete", count: 1 },
  ];
  

  const Profile = () => {
    const [data, setData] = useState(fileAccessData);
  
    const columns = [
      { field: 'file_path', headerName: "File Path", flex: 2 },
      { field: 'user', headerName: "User", width: 100 },
      { field: 'access_type', headerName: "Access Type", width: 120 },
      { field: 'count', headerName: "Count", width: 100, type: 'number' },
    ];
  
    return (
      <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
        <Typography variant="h5">File Access Monitoring</Typography>
  
        {/* Bar Chart */}
        <Box sx={{ height: 300 }}>
          <ResponsiveContainer width="100%" height="100%">
            <BarChart data={data} margin={{ top: 5, right: 15, left: 15, bottom: 5 }}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="file_path" tick={false} />  {/* ✅ X축 라벨 숨김 */}
              <YAxis />
              <Tooltip />
              <Bar dataKey="count" fill="#007bff" />
            </BarChart>
          </ResponsiveContainer>
        </Box>
  
        {/* DataGrid */}
        <DataGrid
          rows={data}
          columns={columns}
          getRowId={(row) => row.file_path}
          initialState={{ pagination: { paginationModel: { page: 0, pageSize: 10 }}}}
          pageSizeOptions={[5, 10, 20]}
          sx={{ border: 0 }}
          getRowHeight={() => 'auto'}
        />
      </Box>
    );
  };
  
  export default Profile;