import React, { useState, useEffect } from 'react';

import { 
  Box, 
  Typography,
  Select,
  MenuItem,
  Switch
} from '@mui/material';

import { DataGrid } from '@mui/x-data-grid';

import { 
  BarChart, 
  Bar, 
  XAxis, 
  YAxis, 
  Tooltip, 
  CartesianGrid, 
  ResponsiveContainer 
} from 'recharts';

import { fetchInstanceProfiling } from '../../../services/InstanceServices';

import { useTranslation } from 'react-i18next';


const paginationModel = { page: 0, pageSize: 5 };

const InstanceProfile = ({iid, selectedInstance, handleIstanceProfilingToggle}) => {
  const { t } = useTranslation(['project', 'common']);
  
  const [selectedNgram, setSelectedNgram] = useState(40);
  const [data, setData] = useState([]);
  const [topK, setTopK] = useState('100');
  
  const columns = [
    { field: 'ngram', 
      headerName: t("SystemCallSequence"), 
      flex: 1, 
      renderCell: (params) => (
        <div style={{ 
          whiteSpace: "normal", 
          wordBreak: "break-word" }}
        >
          {params.value}
        </div>
      ), 
    },
    { 
      field: 'count', 
      headerName: t("common:Count"), 
      width: 70,
      type: 'number'
    },
  ];
  
  useEffect(() => {
    if (iid) {
      fetchInstanceProfile(iid);
    }
  }, [iid, selectedNgram, topK]);

  const fetchInstanceProfile = async (iid) => {
    try {
      setData([]);  // ✅ 데이터 초기화
      const response = await fetchInstanceProfiling(iid, topK);
      let data = await response.json();

      data = filterByWinSize(data);
      data = sortByCountDesc(data);  
      
      setData(data);
    } catch (error) {
      console.error("Error fetching instance:", error);
      setData([]);  // ✅ 오류 발생 시 빈 배열 설정
    }
  };

  const filterByWinSize = (data) => {
    return data.filter((item) => item.window_size === selectedNgram);
  }

  const sortByCountDesc = (data) => {
    return data.sort((a, b) => b.count - a.count);
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 1.5 }}>
      <div className="flex flex-row items-center mb-2">
        <Typography variant="h5">{t("instanceProfiling")}</Typography>
        <div className="flex flex-grow"/>
        <Typography style={{marginRight: "3px"}}>OFF</Typography>
            <Switch 
              checked={selectedInstance.isProfiling} 
              onChange={handleIstanceProfilingToggle} 
              color="black"
            />
        <Typography style={{marginLeft: "3px"}}>ON</Typography>
      </div>
      <div className='flex flex-row items-center'>
        <Typography variant="body1" sx={{ fontWeight: 'bold' }}>{t("topkSystemCallSequenceStatistics")}</Typography>
        <div className='flex-grow'/>
        <Select 
          value={topK} 
          onChange={(e) => setTopK(e.target.value)} 
          sx={{ 
            alignSelf: 'center', 
            fontSize: '15px',
            height: 30,
            minWidth: 100,
            // padding: '5px 10px',
          }}
        >
          <MenuItem value="100">Top 100</MenuItem>
          <MenuItem value="200">Top 200</MenuItem>
          <MenuItem value="500">Top 500</MenuItem>
        </Select>
      </div>
      <Box sx={{ height: 200 }}>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={data} margin={{ top: 5, right: 15, left: 15, bottom: 5 }}>
        <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="sequence" tick={false} />  {/* X축 라벨 숨김 */}
            <YAxis />
            <Tooltip />
            <Bar dataKey="count" fill="#555555" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <DataGrid
        rows={data}
        columns={columns}
        getRowId={(row) => row.ngram}
        initialState={{ pagination: {paginationModel}}}
        pageSizeOptions={[5, 10, 20]}
        sx={{border: 0}}
        getRowHeight={() => 'auto'}
      />
    </Box>
  );
}

export default InstanceProfile;