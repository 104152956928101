import React, { useEffect, useState, useRef } from "react";

import {
    List,
    ListItemButton,
    ListItemText,
    Box,
    CssBaseline,
    Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { fetchInstanceAlert } from '../../services/InstanceServices';

const Alert = ({iid}) => {
    const { t } = useTranslation(['project', 'common']);

    const ref = useRef(null);
    const listRef = useRef(null);
    const [alerts, setAlerts] = useState([]);
    const [count, setCount] = useState(0);

    useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
        if (alerts.length === 0){
          fetchAlerts();
        }
    }, [iid]);

    const fetchAlerts = async () => {
        try {
            const response = await fetchInstanceAlert(iid, count+15);
            const data = []
            // const data = [
            //     {
            //         "instance": "test-save-front",
            //         "type": "Anomaly",
            //         "time": "2025-03-10 22:39:12",
            //         "anomaly_score": 0.852,
            //         "threshold": 0.8,
            //         "degree": "5px solid red"
            //     },
            //     {
            //         "instance": "test-save-front",
            //         "type": "Anomaly",
            //         "time": "2025-03-10 22:38:11",
            //         "anomaly_score": 0.832,
            //         "threshold": 0.8,
            //         "degree": "5px solid red"
            //     },
            //     {
            //         "instance": "CASB_Agent_Test",
            //         "type": "Anomaly",
            //         "time": "2025-03-08 03:12:48",
            //         "anomaly_score": 0.895,
            //         "threshold": 0.8,
            //         "degree": "5px solid red"
            //     },
            //     {
            //         "instance": "test_save_back",
            //         "type": "Anomaly",
            //         "time": "2025-03-07 13:48:23",
            //         "anomaly_score": 0.932,
            //         "threshold": 0.8,
            //         "degree": "5px solid red"
            //     },
            //     {
            //         "instance": "test_save_back",
            //         "type": "Anomaly",
            //         "time": "2025-03-01 18:06:42",
            //         "anomaly_score": 0.802,
            //         "threshold": 0.8,
            //         "degree": "5px solid red"
            //     },
            // ]
            setAlerts(prevAlerts => [...prevAlerts, ...processAlerts(data)]);
            if (response.status === 200) {
                // const data = await response.json();
                
                setAlerts(prevAlerts => [...prevAlerts, ...processAlerts(data)]);

                setCount(count + 15);
            }
            
            return true;
        } catch (error) {
            console.error("Error fetching alerts:", error);
            return false;
        }
    }

    const processAlerts = (alerts) => {
        const processedAlerts = alerts.map(alert => {
            return {
                primary: t(alert.type),
                secondary: (
                  <>
                        {t("Instance")}: {alert.instance} <br />
                        {t("common:Time")}: {alert.time} <br />
                        {t("anomalyScore")}: {alert.anomaly_score} <br />
                        {t("threshold")}: {alert.threshold}
                  </>
                ),
                degree: alert.degree
            }
        });

        console.log(processedAlerts);

        return processedAlerts;
    }

    const handlleLoadMore = async () => {
      await fetchAlerts();
    }

    return (
      <div
          style={{
              display: "flex",
              flexDirection: "column",
              height: "100%",
              overflow: "hidden",
          }}
          ref={ref}
      >    
          <CssBaseline />
          <div className='flex pl-3'>
              <Typography variant="h5">
                  {t("Alert")}
              </Typography>
          </div>
          <Box
              sx={{
                  flexGrow: 1,  // ✅ 높이를 가득 채우도록 설정
                  overflow: "auto",  // ✅ 스크롤 가능하도록 설정
                  maxHeight: "750px", // ✅ 최대 높이 설정 (필요에 따라 조정)
              }}
          >
              <List>
                  {alerts.map(({ primary, secondary, person, degree }, index) => (
                      <ListItemButton 
                          key={index + person} 
                          sx={{
                              border: "1px solid #e0e0e0",
                              py: "0.25px",
                              pl: 0,
                              pr: "3px",
                          }}
                          >
                          <ListItemText 
                              sx={{
                                  borderLeft: degree,
                                  paddingLeft: "10px",
                              }}
                              primary={primary} 
                              secondary={secondary} 
                          />
                      </ListItemButton>
                  ))}
                  <div className="flex justify-center">
                    <button 
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
                        onClick={handlleLoadMore}
                    >
                        {t("common:ShowMore")}
                    </button>
                  </div>
              </List>
          </Box>
      </div>
    );
}

export default Alert;