import React, { useContext, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';
import { fetchSignIn } from '../../services/AuthServices';
import SignInForm from './SignInForm';
import Logo from '../../static/img/csrc_logo_big.jpg';

import {
    Avatar,
    Box,
    Container,
    CssBaseline,
    Divider,
    Typography,
    Grid,
} from '@mui/material';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';


const SignIn = () => {
    const navigate = useNavigate();
    const { setAuthState } = useContext(AuthContext);
    const { setUserState } = useContext(UserContext);
    const [formErrors, setFormErrors] = useState({
        email: '',
        password: '',
    });
    
    const { t } = useTranslation('authentication');
    const handleNavigation = useNavigationForLang();

    const handleSignIn = async (email, password) => {
        if (validateForm(email, password)) {
            try {
                const response = await fetchSignIn(email, password);
                handleSignInResponse(response);
            } catch (error) {
                alert('Network error. Please try again later.');
                console.log(error);
            }
        }
    };

    const validateForm = (username, password) => {
        const errors = {
            username: username ? '' : 'Please enter your ID.',
            password: password ? '' : 'Please enter your password.',
        };
        setFormErrors(errors);
        return !errors.username && !errors.password;
    };

    const handleSignInResponse = (response) => {
        if (response.ok) {
            setUserState({ isLoading: true });
            setAuthState({ isAuthenticated: true, isLoading: false });
            handleNavigation('/dashboard');
        } else if (response.status === 401) {
            alert('Invalid username or password.');
        } else {
            alert('Unknown error. Please try again later.');
        }
    };

    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
            }}
        >
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <LockOutlinedIcon />
            </Avatar>
            <Typography component="h1" variant="h5">
                {t('signin')}
            </Typography>
            <SignInForm
                handleSignIn={handleSignIn}
                formErrors={formErrors}
            />
        </Box>
    );
};

export default SignIn;
