import React, {useRef, useState, useContext} from "react";

import {
    Box,
    Paper,
    List,
    ListItem,
    ListItemButton,
    Typography
} from "@mui/material";

import { useTranslation } from "react-i18next";

import { ProjectNavBarContext } from "./ProjectNavBarContext";

const ProjectNavBar = () => {
    const { t } = useTranslation(["projectHome", "common"]);

    const navRef = useRef(null);
    const [activeSection, setActiveSection] = useState(null);

    const { sections, scrollToSection } = useContext(ProjectNavBarContext) || { sections: [], scrollToSection: () => {} };

    return (
        <Box
            ref={navRef}
            sx={{
                width: "10%",
                minWidth: "150px",
                position: "sticky", // 🔥 스크롤 따라오도록 설정
                top: 0, // 상단 여백 설정
                height: "100vh",
                overflowY: "auto", // 스크롤 허용
                flexShrink: 0,
                paddingTop: "25pt",
                marginLeft: "20pt",
            }}
        >
            <Paper 
                sx={{ 
                    borderRadius: 2, 
                    // boxShadow: "4px 4px 10px rgba(0, 0, 0, 0.2)",
                    margin: "3pt",
                    padding: "3pt",
                    boxShadow: "1px 1px 2px rgba(0, 0, 0, 0.15), \
                                -1px -1px 2px rgba(0, 0, 0, 0.1), \
                                1px -1px 2px rgba(0, 0, 0, 0.1), \
                                -1px 1px 2px rgba(0, 0, 0, 0.15)"
                }}
            >
                <Typography variant="h6" sx={{ fontWeight: "bold", color: "#004B6B", px: "5px" }}> 
                    {t("common:Index")}
                </Typography>
                <List sx={{ padding: 0}}>
                    {sections.map((section) => (
                    <ListItem 
                        key={section.id} 
                        disablePadding
                    >
                        <ListItemButton
                            onClick={() => scrollToSection(section.ref)}
                            sx={{
                                bgcolor: activeSection === section.id ? "#FFD166" : "transparent", // 🔥 강조색을 연한 주황색으로 변경
                                "&:hover": { bgcolor: "#FFEA99" }, // 🔥 호버시 더 밝은 연노랑으로 변경
                                padding: "5pt"
                            }}
                        >
                            <Typography 
                                variant="body2" // ✅ 기본적인 폰트 크기 설정 (변경 가능)
                                sx={{ fontSize: "11pt", color: "#003F5C", padding: 0, }} 
                            >
                                {section.label}
                            </Typography>
                        </ListItemButton>
                    </ListItem>
                    ))}
                </List>
            </Paper>
        </Box>
    );
}

export default ProjectNavBar;