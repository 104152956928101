import React, { useState, useContext, useCallback } from 'react';

import { AuthContext } from '../../core/auth';
import { UserContext } from '../../core/user';

import { 
    Button, 
    Typography,
    IconButton,
    Menu,
    MenuItem,
    Box,
} from '@mui/material';

import AccountCircle from '@mui/icons-material/AccountCircle';
import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';

import { fetchSignOut } from '../../services/AuthServices';
import { syncInstances } from '../../services/InstanceServices';

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';

const UserMenuContent = ({ userState }) => (
    <div className="flex flex-col space-y-2">
        {userState.logo !== null && (
            <Box
                component="img"
                src={userState.logo}
                alt="User Logo"
                backgroundColor="transparent"
                maxHeight={120}
                alignContent="center"
            />
        )}
        <div className="flex flex-row justify-between items-center">
            <PersonOutlineIcon fontSize="large" />
            <div className="flex flex-col justify-start px-4">
                <Typography color="neutral.light">
                    {userState.username}
                </Typography>
                <Typography sx={{ fontWeight: 'bold' }}>
                    {userState.company_name}
                </Typography>
            </div>
            <ArrowForwardIosIcon fontSize="small" color="neutral.light" />
        </div>
    </div>
);

const UserIcon = ({color="#000000"}) => {
    const { t } = useTranslation(['authentication', "project"]);
    const handleNavigation = useNavigationForLang();

    const { setAuthState } = useContext(AuthContext);
    const { userState } = useContext(UserContext);

    const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
    
    const handleSignOut = useCallback(() => {
        fetchSignOut().finally(() => {
            setAuthState({ isAuthenticated: false, isLoading: false });
            handleNavigation('/home');
        });
    }, [setAuthState, handleNavigation]);

    const handleUserMenuClick = useCallback((event) => {
        setUserMenuAnchorEl(event.currentTarget);
    }, []);

    const handleUserMenuClose = useCallback(() => {
        setUserMenuAnchorEl(null);
    }, []);

    const handleSync = async () => {
        if (!userState?.is_admin) {
            alert("❌ 관리자만 Sync 기능을 사용할 수 있습니다.");
            return;
        }

        try {
            console.log("🔄 Syncing instances...");
            console.log("User UID:", userState.uid);

            const response = await syncInstances(userState.uid);
            if (response.ok) {
                const updatedData = await response.json();
                alert("✅ Instances synced successfully!");
            } else {
                alert("❌ Failed to sync instances.");
            }
        } catch (error) {
            console.error("Error syncing instances:", error);
            alert("⚠️ Error occurred while syncing.");
        }
    };

    const renderUserMenu = () => (
        <Menu
            id="user-menu"
            anchorEl={userMenuAnchorEl}
            open={Boolean(userMenuAnchorEl)}
            onClose={handleUserMenuClose}
            MenuListProps={{ 'aria-labelledby': 'user-button' }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        >
            <MenuItem
                onClick={() => handleNavigation('/myPage')}
            >
                <UserMenuContent userState={userState} />
            </MenuItem>
            {userState.is_admin && (
                <MenuItem>
                    <Button
                        fullWidth
                        variant="contained"
                        onClick={handleSync}
                        color="secondary"
                    >
                        {t("project:Sync")}
                    </Button>
                </MenuItem>
            )}
            <MenuItem>
                <Button
                    fullWidth
                    variant="contained"
                    onClick={handleSignOut}
                    color="primary"
                >
                    {t("signout")}
                </Button>
            </MenuItem>
        </Menu>
    );

    return (
        <div>
            <IconButton
                edge="end"
                color="inherit"
                onClick={handleUserMenuClick}
                style={{ marginLeft: "10px", color:{color} }}
            >
                <AccountCircle
                    style={{ color: color }}
                />
            </IconButton>
            {renderUserMenu()}
        </div>
    )
}

export default UserIcon;