import AppConfig from '../../core/config';
import { fetchRefresh } from '../AuthServices';

export const fetchCreateAzureCredential = async (credentials) => {
    const url = `${AppConfig.azure_base_url}/auth/register`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            Accept: 'application/json',
            'Content-Type': 'application/json',
            // Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        credentials: 'include',
        body: JSON.stringify(credentials),
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${localStorage.getItem('token')}`,
                },
                body: JSON.stringify(credentials),
            });

            return retryResponse;
        }
    }
    return response;
};

export const fetchSyncAzureInstances = async () => {
    const url = `${AppConfig.azure_base_url}/auth/sync_instances/`;
    // get api
    const response = await fetch(url, {
        method: 'GET',
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                credentials: 'include',
            });

            return retryResponse;
        }
    }
    return response;
}