import React, { useState, useEffect } from 'react';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Link from '@mui/material/Link';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Title from '../Title';
import AddAclDialog from './AddAclDialog'; // Dialog 컴포넌트 가져오기
import { deleteACL } from '../../services/ACLServices'; // ACL 삭제 API 호출 함수 가져오기

import { useContext } from 'react';
import { UserContext } from '../../core/user';

import { fetchACLs } from '../../services/ACLServices';
import { Paper, TableContainer } from '@mui/material';

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';

const theme = createTheme({
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          fontSize: '12px',
          padding: '4px 12px',
        },
      },
    },
  },
});

function preventDefault(event) {
  event.preventDefault();
}

export default function ACLTable() {
  const [rows, setRows] = useState([]);
  const { userState } = useContext(UserContext);
  const [loading, setLoading] = useState(true);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addACLTrigger, setAddACLTrigger] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  const { t } = useTranslation("project");
  const handleNavigation = useNavigationForLang();

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // 월은 0부터 시작하므로 +1
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');
  
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  // 데이터를 불러오는 함수
  const loadACLs = async () => {
    try {
      const response = await fetchACLs();
      if (response.ok) {
        let data = await response.json();

        // 기존 순서를 유지하면서 `aid` 기준 정렬
        setRows((prevRows) => {
          if (prevRows.length > 0) {
            return data.sort((a, b) => prevRows.findIndex(p => p.aid === a.aid) - prevRows.findIndex(p => p.aid === b.aid));
          } else {
            return data.sort((a, b) => a.aid - b.aid);
          }
        });

        console.log('Fetched ACLs:', data);
      } else {
        console.error('Failed to fetch ACLs:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching ACLs:', error);
    } finally {
      setLoading(false);
    }
  };
  

  const handleDialogOpen = () => setDialogOpen(true);
  const handleDialogClose = () => setDialogOpen(false);

  const handleAddAcl = () => {
    setAddACLTrigger((prev) => !prev);
  };

  const handleRowClick = (row) => {
    setSelectedRow(row.aid === selectedRow ? null : row.aid); // 클릭 시 행 선택/해제
  };

  const handleDelete = async () => {
    if (!selectedRow) {
      alert('Please select a row to delete.');
      return;
    }

    try {
      const response = await deleteACL(selectedRow); // ACL 삭제 API 호출
      if (response.ok) {
        alert('ACL successfully deleted.');
        setAddACLTrigger((prev) => !prev); // 테이블 다시 로드
        setSelectedRow(null); // 선택 초기화
      } else {
        alert('Failed to delete ACL.');
      }
    } catch (error) {
      console.error('Error deleting ACL:', error);
      alert('An error occurred while deleting ACL.');
    }
  };

  // 컴포넌트 마운트 시 데이터 로드
  useEffect(() => {
    loadACLs();
  }, [addACLTrigger]);

  return (
    <React.Fragment>
      
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 2, // 하단 마진 추가
        }}
      >
        {/* Title */}
          <Title>{t("accessControlList")}</Title>

          {/* 버튼 그룹 */}
          <ThemeProvider theme={theme}>
          {/* <Box sx={{ display: 'flex', gap: 1 }}>
          <Button
              variant="contained"
              color="primary"
              sx={{ fontWeight: 'bold' }}
              onClick={handleDialogOpen}
            >
              ADD
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ fontWeight: 'bold' }}
              onClick={handleDelete}
            >
              DELETE
            </Button>
          </Box> */}
        </ThemeProvider>
      </Box>

      <Box sx={{ width: '100%', maxWidth: '100vw', overflowX: 'auto' }}>
        <TableContainer component={Paper} sx={{ width: '100%', maxWidth: '100vw' }}>
          <Table sx={{ width: '100%', minWidth: '1200px', tableLayout: 'auto' }}>
            <TableHead>
              <TableRow>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("csp")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("instanceName")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("flowDirection")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("sourceIP")} ({t("vpn")})</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("destinationIP")} ({t("vpn")})</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("port")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("protocol")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("description")}</TableCell>
                <TableCell sx={{ fontWeight: 'bold' }}>{t("updateAt")}</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rows.map((row) => (
                <TableRow
                  key={row.aid}
                  onClick={() => handleRowClick(row)}
                  sx={{
                    cursor: 'pointer',
                    backgroundColor: row.is_allow ? '#d4edda' : '#f8d7da', // ✅ Allow → 초록색 / Deny → 빨간색
                    '&:hover': {
                      backgroundColor: row.is_allow ? '#c3e6cb' : '#f5c6cb', // ✅ Hover 시 색상 조정
                    },
                  }}
                >
                  <TableCell>{row.instance.csp_name}</TableCell>
                  <TableCell>{row.instance.instance_name}</TableCell>
                  <TableCell>{row.direction}</TableCell>
                  <TableCell>{row.src_public_ip} ({row.src_private_ip || 'N/A'})</TableCell>
                  <TableCell>{row.dst_public_ip} ({row.dst_private_ip || 'N/A'})</TableCell>
                  <TableCell>{row.port}</TableCell>
                  <TableCell>{row.protocol.toUpperCase()}</TableCell>
                  <TableCell>{row.description}</TableCell>
                  <TableCell>{formatDate(row.updated_at)}</TableCell>
                </TableRow>
              ))}
            </TableBody>

          </Table>
        </TableContainer>
      </Box>

      <AddAclDialog open={dialogOpen} onClose={handleDialogClose} onSubmit={handleAddAcl} />
    </React.Fragment>
  );
}