import React, { useEffect, useState } from "react";
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from "@mui/material";
import { 
    MapContainer, 
    TileLayer, 
    Marker, 
    Tooltip,
    useMap
} from "react-leaflet";
import "leaflet/dist/leaflet.css";
import L from "leaflet";
import CircleLogo from '../../../components/cspLogo/CircleLogo';

// Fix default marker icon issue
delete L.Icon.Default.prototype._getIconUrl;
L.Icon.Default.mergeOptions({
    iconUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon.png",
    iconRetinaUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-icon-2x.png",
    shadowUrl: "https://unpkg.com/leaflet@1.9.4/dist/images/marker-shadow.png",
});

const locations = [
    { location: "us-east-1", position: [38.0336, -78.5079] }, // 버지니아 (버지니아 북부)
    { location: "us-east-2", position: [39.9612, -82.9988] }, // 오하이오
    { location: "us-west-1", position: [37.7749, -122.4194] }, // 캘리포니아 (샌프란시스코)
    { location: "us-west-2", position: [45.5234, -122.6762] }, // 오리건 (포틀랜드)
    { location: "ca-central-1", position: [43.6532, -79.3832] }, // 캐나다 (토론토)
    { location: "eu-west-1", position: [53.3498, -6.2603] }, // 아일랜드 (더블린)
    { location: "eu-west-2", position: [51.5074, -0.1278] }, // 영국 (런던)
    { location: "eu-west-3", position: [48.8566, 2.3522] }, // 프랑스 (파리)
    { location: "eu-central-1", position: [50.1109, 8.6821] }, // 독일 (프랑크푸르트)
    { location: "eu-north-1", position: [59.3293, 18.0686] }, // 스웨덴 (스톡홀름)
    { location: "ap-northeast-1", position: [35.6895, 139.6917] }, // 일본 (도쿄)
    { location: "ap-northeast-2", position: [37.5665, 126.9780] }, // 대한민국 (서울)
    { location: "ap-northeast-3", position: [34.6937, 135.5023] }, // 일본 (오사카)
    { location: "ap-southeast-1", position: [1.3521, 103.8198] }, // 싱가포르
    { location: "ap-southeast-2", position: [-33.8688, 151.2093] }, // 호주 (시드니)
    { location: "ap-south-1", position: [19.0760, 72.8777] }, // 인도 (뭄바이)
    { location: "koreacentral", position: [37.5665, 126.9780] }, // 대한민국 (서울, Azure)
];

const SetViewOnLoad = () => {
    const map = useMap();

    useEffect(() => {
        map.fitBounds([[-60, -180], [75, 180]]);
        const worldBounds = L.latLngBounds([[-60, -180], [75, 180]]);
        map.setMaxBounds(worldBounds);
    }, [map]);

    return null;
};

const MapComponent = ({ instances }) => {
    const [mapData, setMapData] = useState([]);

    useEffect(() => {
        if (!instances || instances.length === 0) return;

        // 🔹 위치별 그룹화 (같은 위치는 하나의 마커로 유지)
        const groupedMapData = {};

        instances.forEach((instance) => {
            const foundLocation = locations.find((loc) => loc.location === instance.instance_location);
            if (!foundLocation) return;

            const positionKey = foundLocation.position.join(","); 

            if (!groupedMapData[positionKey]) {
                groupedMapData[positionKey] = {
                    position: foundLocation.position,
                    cspGroups: {},
                };
            }

            if (!groupedMapData[positionKey].cspGroups[instance.csp_name]) {
                groupedMapData[positionKey].cspGroups[instance.csp_name] = [];
            }
            groupedMapData[positionKey].cspGroups[instance.csp_name].push(instance.instance_name);
        });

        const results = Object.values(groupedMapData);
        setMapData(results);
    }, [instances]);

    return (
        <div style={{ width: "100%", height: "500px", maxWidth: "100%" }}> 
            <MapContainer 
                style={{ width: "100%", height: "100%" }} 
                zoom={1} 
                minZoom={2} 
                maxZoom={5}
                maxBounds={[[-60, -180], [75, 180]]}
                maxBoundsViscosity={1.0}
                worldCopyJump={false}
                noWrap={true}
            >
            <TileLayer
                url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                noWrap={true}
            />
            
            <SetViewOnLoad />
            
            {mapData.map((location, idx) => (
                <Marker key={`marker-${idx}`} position={location.position}>
                    <Tooltip>
                        <List dense style={{ padding: "5px", background: "white", borderRadius: "5px" }}>
                            {Object.entries(location.cspGroups).map(([csp, descriptions], index) => (
                                <div key={`csp-${csp}-${index}`}>
                                    <ListItem disableGutters>
                                        <CircleLogo csp={csp} width={20} height={20} />
                                        <ListItemText 
                                            primary={`CSP: ${csp}`} 
                                            style={{ fontWeight: "bold", color: "#2196f3", marginLeft: "6px" }} 
                                        />
                                    </ListItem>
                                    {descriptions.map((desc, idx) => (
                                        <ListItem key={`desc-${csp}-${idx}`} disableGutters>
                                            <ListItemText primary={desc} style={{ fontWeight: "bold", color: "#333" }} />
                                        </ListItem>
                                    ))}
                                </div>
                            ))}
                        </List>
                    </Tooltip>
                </Marker>
            ))}
            </MapContainer>
        </div>
    );
};

export default MapComponent;
