import React, { useState, useContext, useCallback } from 'react';
import { 
    Button, 
    Divider,
} from '@mui/material';


import MenuIcon from '@mui/icons-material/Menu';
import theme from '../../theme';

import LanguageSelector from '../header/LanguageSelector';

import { useNavigationForLang } from '../../i18n';

import { HeaderIcon } from '../header/HeaderIcon';

import UserIcon from './UserIcon';

const Header = ({ handleNavbar }) => {
    const handleNavigation = useNavigationForLang();

    return (
        <div className="flex flex-row border-b border-gray-400 bg-white items-center"
            style={{ 
                height: "50px", 
                paddingRight: "30px",
                paddingTop: "10px",
                paddingBottom: "10px",
                backgroundColor: "#0E548D"
            }}
        >
            <Button 
                onClick={handleNavbar}
                style={{ height: "100%" }}
            >
                <MenuIcon style={{ color: "white" }} />
            </Button>
            <Divider 
                style={{ height: "30px", marginRight: "20px", color: "white" }}
                orientation="vertical" 
            />
            <HeaderIcon
                onClickLogo={() => handleNavigation('/dashboard')}
                color="white"
            />
            <div className="flex-grow" />
            <LanguageSelector color="white"/>
            <UserIcon color="white"/>
        </div>
    );
};

export default Header;