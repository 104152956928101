import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

// import {
//     fetchCreateProject,
// } from '../services/ProjectServices';

import { Divider, Stack, Typography, Button } from '@mui/material';

import { useTranslation } from 'react-i18next';

import AWSCard from '../../components/card/AWSCard';
import AzureCard from '../../components/card/AzureCard';
import OpenstackCard from '../../components/card/OpenstackCard';

const RegisterCSP = ({onSelectCSP}) => {
    const { t } = useTranslation(['project', 'common']);

    const [newProjectType, setNewProjectType] = useState('');

    const [isRendering, setIsRendering] = useState(false);
    const [showAlertText, setShowAlertText] = useState(false);

    const navigate = useNavigate();

    const handleCreate = async () => {
        if (newProjectType === '') {
            setShowAlertText(true);
            return;
        }
        else {
            setShowAlertText(false);
            onSelectCSP(newProjectType);
        }
    };

    const onClickCard = (e, project_type) => {
        setNewProjectType(project_type);
    };

    return (
        <div className="flex flex-col w-full justify-center items-center">
            <Stack spacing={2} sx={{ padding: 5, boxShadow: 3 }}>
                <div style={{fontWeight: "bold", fontSize: "20pt", textAlign: "center"}}>
                    {t("project:selectACloudType")}
                </div>
                <div className="flex flex-col w-1/2 space-y-4">
                    <Typography variant="h6">{t("common:PublicCloud")}</Typography>
                </div>
                <div className="flex flex-row justify-center w-full space-x-4">
                    <AWSCard 
                        onClickCard={onClickCard}
                        selected={newProjectType == 'AWS'}
                    />
                    <AzureCard 
                        onClickCard={onClickCard} 
                        selected={newProjectType == 'Azure'}
                    />
                </div>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <Typography variant="h6">{t("common:PrivateCloud")}</Typography>
                <div className="flex flex-grow justify-center w-full space-x-4">
                    <OpenstackCard
                        onClickCard={onClickCard}
                        selected={newProjectType == 'Openstack'}
                    />
                </div>
                <Divider sx={{ mt: 2, mb: 2 }} />
                <div className="flex flex-row justify-center w-full space-x-4">
                    <Button 
                        variant="contained" 
                        onClick={handleCreate}
                    >
                        {t("common:Next")}
                    </Button>
                </div>
                {showAlertText && (
                    <div className='flex flex-row justify-center items-center'>
                        <Typography variant="body2" color="error">
                            {t("project:PleaseSelectACloudType")}
                        </Typography>
                    </div>
                )}
            </Stack>
        </div>
    );
};

export default RegisterCSP;
