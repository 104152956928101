import React, { useRef, useState, useEffect, useContext } from "react";

import { 
    Box, 
    Typography, 
    Paper, 
    Table, 
    TableBody, 
    TableCell, 
    TableContainer, 
    TableHead, 
    TableRow,
} from "@mui/material";

import casbDiagram from "../../assets/images/casb_architecture.png"; // CASB 다이어그램 이미지

import { useTranslation } from "react-i18next";

import { ProjectNavBarContext } from "../../components/navbar/ProjectNavBarContext";

const Project = () => {
  // 📌 섹션별 ref (스크롤 이동을 위해 사용)
  const { t } = useTranslation(["projectHome", "common"]);

  const { sections } = useContext(ProjectNavBarContext) || { sections: [] };

  useEffect(() => {
    const handleScroll = () => {
      const offset = 100; // 감지 높이 오차 조정
      const scrollPosition = window.scrollY + offset;

      for (const section of sections) {
        if (section.ref.current) {
          const { offsetTop, offsetHeight } = section.ref.current;
          if (scrollPosition >= offsetTop && scrollPosition < offsetTop + offsetHeight) {
            break;
          }
        }
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <div className="flex w-full">
        {/* 📌 설명 컨텐츠 영역 (우측) */}
        <Box sx={{ width: "100%", px: 4, justifyContent: "space-between", alignItems: "center", mb: 2 }}>
            {/* 1. CSPM이란? */}
            <Paper ref={sections[0].ref} sx={{ p: 5, mb: 6, width: "100%", }}>
                {/* CSPM 제목 */}
                <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
                    ■ {t("CSPM")} (CSPM, CISA 2022)
                </Typography>
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", ml: 3 }}>
                    ● {t("Definition&PurposeOfCSPM")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - {t("descriptionCSPM1")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - {t("descriptionCSPM2")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - {t("descriptionCSPM3")}
                </Typography>

                {/* CSPM 제공 기능 (테이블) */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", ml: 3 }}>
                    ● {t("keyCapabilitiesOfCSPM")}
                </Typography>

                <TableContainer component={Paper} sx={{ mt: 2, ml: 3, mr: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold", width: "30%" }}>{t("common:Capability")}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{t("common:Description")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCSPM1")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCSPM1")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCSPM2")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCSPM2")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCSPM3")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCSPM3")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCSPM4")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCSPM4")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCSPM5")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCSPM5")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCSPM6")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCSPM6")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCSPM7")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCSPM7")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* 2. CASB란? */}
            <Paper ref={sections[1].ref} sx={{ p: 5, mb: 6, width: "100%", }}>
                {/* CASB 제목 */}
                <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", ml: 3 }}>
                    ■ {t("whatIsCASB")}?
                </Typography>

                {/* CASB 정의 및 목적 */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", ml: 3 }}>
                    ● {t("Definition&PurposeOfCASB")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("common:Definition")}:</strong> {t("definitionCASB")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("common:Purpose")}:</strong> {t("purposeCASB")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - {t("descriptionCASB")}
                </Typography>

                {/* CASB 주요 기능 테이블 */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", ml: 3, mt: 3 }}>
                    ● {t("keyCapabilitiesOfCASB")}
                </Typography>

                <TableContainer component={Paper} sx={{ mt: 2, ml: 3, mr: 3 }}>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell sx={{ fontWeight: "bold", width: "30%" }}>{t("common:Capability")}</TableCell>
                                <TableCell sx={{ fontWeight: "bold" }}>{t("common:Description")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCASB1")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCASB1")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCASB2")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCASB2")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCASB3")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCASB3")}</TableCell>
                            </TableRow>
                            <TableRow>
                                <TableCell><strong>{t("capabilityCASB4")}</strong></TableCell>
                                <TableCell>{t("capDescriptionCASB4")}</TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>

            {/* 3. Reverse Proxy 기반 CASB의 필요성 */}
            <Paper ref={sections[2].ref} sx={{ p: 5, mb: 6, width: "100%", }}>
                {/* Reverse Proxy 기반 CASB 제목 */}
                <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold", ml: 3 }}>
                    ■ {t("whyDevelopAReverseProxyBasedCASB")}?
                </Typography>

                {/* 기존 CASB 방식의 한계 */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", ml: 3 }}>
                    ● {t("limitationOfTraditionalCASBSolutions")}
                </Typography>

                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("limitCASB1")}</strong>: {t("limitDescCASB1")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("limitCASB2")}</strong>: {t("limitDescCASB2")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("limitCASB3")}</strong>: {t("limitDescCASB3")}
                </Typography>

                {/* Reverse Proxy CASB의 필요성 */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", ml: 3, mt: 3 }}>
                    ● {t("advantageOfReverseProxyBasedCASB")}
                </Typography>

                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("advCASB1")}</strong>: {t("advDescCASB1")}
                </Typography>

                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("advCASB2")}</strong>: {t("advDescCASB2")}
                </Typography>

                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    - <strong>{t("advCASB3")}</strong>: {t("advDescCASB3")}
                </Typography>

                {/* 중앙 정렬된 CASB 다이어그램 */}
                <Box sx={{ textAlign: "center", mt: 4 }}>
                    <img src={casbDiagram} alt="CASB Architecture" style={{ width: "80%", height: "auto", display: "block", margin: "0 auto" }} />
                </Box>

                {/* Reverse Proxy CASB 요약 */}
                <Typography variant="body1" sx={{ mt: 4, textAlign: "center", fontWeight: "bold" }}>
                    {t("conclusionCASB")}
                </Typography>
            </Paper>

            {/* 4. 우리의 차별성 */}
            <Paper ref={sections[3].ref} sx={{ p: 5, mb: 6, width: "100%",}}>
                <Typography variant="h4" gutterBottom sx={{ fontWeight: "bold" }}>
                    ■ {t("ourUniqueApproach")}
                </Typography>

                {/* 1. Real-Time Anomaly Detection */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", mt: 3 }}>
                    ● {t("approach1")}
                </Typography>
                <Typography
                    variant="body1"
                    paragraph
                    sx={{ ml: 6, whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{ __html: t("desc1Approach1") }}
                />
                <Typography
                    variant="body1"
                    paragraph
                    sx={{ ml: 6, whiteSpace: "pre-line" }}
                    dangerouslySetInnerHTML={{ __html: t("desc2Approach1") }}
                />

                {/* 2. Server-Centric Access Control */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", mt: 3 }}>
                    ● {t("approach2")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    {t("desc1Approach2")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    {t("desc2Approach2")}
                </Typography>

                {/* 3. UEBA (User and Entity Behavior Analytics) */}
                <Typography variant="h6" gutterBottom sx={{ fontWeight: "bold", mt: 3 }}>
                    ● {t("approach3")} 
                    {/* ● {t("approach3")} <em style={{ color: "red" }}>[{t("common:InDevelopment")}]</em> */}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    {t("desc1Approach3")}
                </Typography>
                <Typography variant="body1" paragraph sx={{ ml: 6 }}>
                    {t("desc2Approach3")}
                </Typography>
            </Paper>
        </Box>
    </div>
  );
};

export default Project;
