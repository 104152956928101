import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import PropTypes from 'prop-types';

import { CircularProgress } from '@mui/material';

import { UserContext } from '../../core/user';

import { useNavigationForLang } from '../../i18n';

const RedirectionRoute = () => {
    const { userState } = useContext(UserContext);

    const handleNavigation = useNavigationForLang();

    if (userState?.isLoading) {
        return <CircularProgress />;
    }

    if (!userState) {
        // return <Navigate to="/en/home" />;
        return <Navigate to={handleNavigation("/home")} />;
    }
    else {
        return <Navigate to={handleNavigation("/dashboard")} />;
    }
};

RedirectionRoute.propTypes = {
    children: PropTypes.node,
};

export default RedirectionRoute;
