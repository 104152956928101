import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import {
    TextField,
    InputAdornment,
    Button,
    Typography,
    Radio,
    RadioGroup,
    FormControlLabel,
    FormControl,
    FormLabel,
    Avatar
} from '@mui/material';

import PersonOutlineIcon from '@mui/icons-material/PersonOutline';
import PasswordIcon from '@mui/icons-material/Password';
import BusinessIcon from '@mui/icons-material/Business';

import PersonAddIcon from '@mui/icons-material/PersonAdd';

import { fetchSignUp } from '../../services/AuthServices';

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';

const SignUp = () => {
    const navigate = useNavigate();

    const { t } = useTranslation(['authentication', 'common']);
    const handleNavigation = useNavigationForLang();

    const [username, setUsername] = useState('');
    const [usernameError, setUsernameError] = useState(false);
    const [usernameErrorText, setUsernameErrorText] = useState('');
    
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');
    
    const [confirmPassword, setConfirmPassword] = useState('');
    const [confirmPasswordError, setConfirmPasswordError] = useState(false);

    const [company_name, setCompany_Name] = useState('');
    const [companyNameError, setCompanyNameError] = useState(false);
    const [companyNameErrorText, setCompanyNameErrorText] = useState('');

    const [isAdmin, setIsAdmin] = useState(true);

    const onSubmit = async (e) => {
        e.preventDefault();

        if (password !== confirmPassword) {

            setConfirmPasswordError(true);
            return;
        }

        const signUpForm = {
            username,
            email,
            password,
            company_name,
            is_admin: isAdmin,
        }

        const response = await fetchSignUp(signUpForm);

        if (response.ok) {
            alert(t("signupSuccess"));
            handleNavigation('/signin');
        } else {
            errorCheck(response);
        }
    };

    const errorCheck = async (response) => {
        const errorText = await response.text();

        setUsernameError(false);
        setEmailError(false);
        setPasswordError(false);
        setCompanyNameError(false);

        try {
            const errorJson = JSON.parse(errorText);
            console.log(errorJson);

            errorJson.detail.forEach((error) => {
                if (error.loc[0] === 'body') {
                    const field = error.loc[1];
                    if (field === 'username') {
                        setUsernameError(true);
                        setUsernameErrorText(error.msg);
                    } else if (field === 'email') {
                        setEmailError(true);
                        setEmailErrorText(error.msg);
                    } else if (field === 'password') {
                        setPasswordError(true);
                        setPasswordErrorText(error.msg);
                    } else if (field === 'company_name') {
                        setCompanyNameError(true);
                        setCompanyNameErrorText(error.msg);
                    }
                }
            });
        } catch (error) {
            console.log(error);
        }
    }


    const handleKeyDown = (e) => {
        console.log(e.key);
        if (e.key === 'Enter') {
            onSubmit(e);
        }
    };

    return (
        <div className='flex flex-col items-center'>
            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                <PersonAddIcon />
            </Avatar>
            <Typography component="h1" variant="h5" sx={{mb: 3}}>
                {t('signup')}
            </Typography>
            <div className='flex flex-row items-center w-full'>
                <Typography>{t("accountType")}</Typography>
                <div className='flex-grow'/>
                <RadioGroup
                    row
                    aria-labelledby="demo-controlled-radio-buttons-group"
                    name="controlled-radio-buttons-group"
                    value={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.value)}
                >
                    <FormControlLabel value={true} control={<Radio />} label={t("admin")} />
                    <FormControlLabel value={false} control={<Radio />} label={t("user")} />
                </RadioGroup>
            </div>
            <TextField
                margin="normal"
                required
                fullWidth
                label={t("name")}
                name="name"
                autoComplete="name"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                // error={!!formErrors.username}
                // helperText={formErrors.username}
                onKeyDown={handleKeyDown}
            />
            {usernameError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {usernameErrorText}
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label={t("Email")}
                name="email"
                autoComplete="email"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PersonOutlineIcon />
                        </InputAdornment>
                    ),
                }}
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                // error={!!formErrors.username}
                // helperText={formErrors.username}
                onKeyDown={handleKeyDown}
            />
            {emailError && 
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {emailErrorText}
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label={t("password")}
                // type="password"
                id="password"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PasswordIcon />
                        </InputAdornment>
                    ),
                }}
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {passwordError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {passwordErrorText}
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label={t("confirmPassword")}
                type="password"
                id="confirmPassword"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <PasswordIcon />
                        </InputAdornment>
                    ),
                }}
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {confirmPasswordError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {t("mismatchPassword")}
                </Typography>
            }
            <TextField
                margin="normal"
                required
                fullWidth
                label={t("company")}
                name="companyName"
                autoComplete="companyName"
                InputProps={{
                    startAdornment: (
                        <InputAdornment position="start">
                            <BusinessIcon />
                        </InputAdornment>
                    ),
                }}
                value={company_name}
                onChange={(e) => setCompany_Name(e.target.value)}
                onKeyDown={handleKeyDown}
            />
            {companyNameError &&
                <Typography 
                    variant="caption" 
                    color="error"
                >
                    {companyNameErrorText}
                </Typography>
            }
            <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2 }}
                onClick={onSubmit}
            >
                {t('signup')}
            </Button>
        </div>
    );
}

export default SignUp;