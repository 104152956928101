import React, { useState, useEffect } from 'react';

import { useNavigate } from 'react-router-dom';

import { 
  Box,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableContainer 
} from '@mui/material';

import Title from '../Title'; 

import { useTranslation } from 'react-i18next';
import { useNavigationForLang } from '../../i18n';
import CircleLogo from '../../components/cspLogo/CircleLogo';

import { fetchInstances } from '../../services/InstanceServices';


const CSP = ['AWS', 'Azure', 'GCP'];

const InstanceTable = () => {
  const { t } = useTranslation("project");
  const handleNavigation = useNavigationForLang();
  
  const [awsRows, setAwsRows] = useState([]);
  const [azureRows, setAzureRows] = useState([]);
  const [gcpRows, setGcpRows] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const response = await fetchInstances(); // ✅ API 호출
    const data = await response.json();
    processingData(data);
  }

  const processingData = (data) => {
    const awsData = data.filter((row) => row.csp_name === 'AWS');
    const azureData = data.filter((row) => row.csp_name === 'Azure');
    const gcpData = data.filter((row) => row.csp_name === 'GCP');

    setAwsRows(awsData);
    setAzureRows(azureData);
    setGcpRows(gcpData);
  }

  // ✅ Row 클릭 시 InstanceDetailPage로 이동
  const handleRowClick = (iid, csp_name) => {
    handleNavigation(`/Instance/${csp_name}/${iid}`);
    // navigate(`/Instance/${csp_name}/${iid}`); // URL에 iid를 포함하여 이동
  };

  return (
    <div>
      <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
        <Title>{t("instanceList")}</Title>
      </Box>

      {/* ✅ CSP별로 데이터 분리 */}
      { CSP.map((csp, index) => {
        const rows = csp === 'AWS' ? awsRows : csp === 'Azure' ? azureRows : gcpRows;
        return (
          (rows && rows.length > 0 && (
            <Box key={index} sx={{ mb: 2 }}>
              <div className="flex flex-row items-center">
                <CircleLogo csp={csp} width={30} height={30}/>
                <Typography variant="h6" sx={{ fontWeight: 'bold', marginLeft: "10pt" }}>{csp}</Typography>
              </div>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>{t("instanceName")}</TableCell>
                      {/* <TableCell sx={{ fontWeight: 'bold' }}>Instance ID</TableCell> */}
                      <TableCell sx={{ fontWeight: 'bold' }}>{t("instanceType")}</TableCell>
                      {/* <TableCell sx={{ fontWeight: 'bold' }}>State</TableCell> */}
                      <TableCell sx={{ fontWeight: 'bold' }}>{t("instanceOS")}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>{t("publicIP")}</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>VPN IP</TableCell>
                      {/* <TableCell sx={{ fontWeight: 'bold' }}>VPC ID</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Group Name</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Description</TableCell> */}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow 
                          key={row.iid} 
                          sx={{ cursor: 'pointer', '&:hover': { backgroundColor: '#f5f5f5' } }} 
                          onClick={() => handleRowClick(row.iid, row.csp_name)} // ✅ 클릭 시 이동
                      >
                        <TableCell>{row.instance_name}</TableCell>
                        {/* <TableCell>{row.instance_id}</TableCell> */}
                        <TableCell>{row.instance_type}</TableCell>
                        {/* <TableCell>{row.instance_state}</TableCell> */}
                        <TableCell>{row.instance_os}</TableCell>
                        <TableCell>{row.public_ip}</TableCell>
                        <TableCell>{row.vpn_ip}</TableCell>
                        {/* <TableCell>{row.vpc_id}</TableCell> */}
                        {/* <TableCell>
                          {JSON.parse(row.security_groups).map(group => group.GroupName).join(", ")}
                        </TableCell> */}
                        {/* <TableCell>{row.description}</TableCell> */}
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          ))
        );
      })}
    </div>
  );
}

export default InstanceTable;