import Header from '../components/header/Header';
import Footer from '../components/footer/Footer';

import React, { useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';

import VerticalNavbar from '../components/navbar/VerticalNavBar';

import ProjectNavBar from '../components/navbar/ProjectNavBar';
import { ProjectNavBarProvider } from '../components/navbar/ProjectNavBarContext';

const ProjectLayout = () => {
    const [isNavExpanded, setIsNavExpanded] = useState(false);
    const location = useLocation();

    const toggleNavbar = () => {
        setIsNavExpanded(!isNavExpanded);
    };

    return (
        <ProjectNavBarProvider>
            <div className="flex flex-col w-screen">
                <Header handleNavbar={toggleNavbar} />
                <div 
                    className="flex flex-grow"
                    style={{
                        backgroundColor: "#F6F6F6"
                    }}
                >
                    <VerticalNavbar open={isNavExpanded} />
                    { location.pathname.includes("what-is-casb") && <ProjectNavBar /> }
                    <main 
                        className="flex-grow p-5 overflow-auto"
                    >
                        <div className="flex flex-col w-full h-full space-y-2">
                            <Outlet />
                        </div>
                    </main>
                </div>
                <Footer />
            </div>
        </ProjectNavBarProvider>
    );
};

export default ProjectLayout;