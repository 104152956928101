import React, { use, useState, useEffect } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Paper, Typography, Link } from '@mui/material';

import CloudInfo from './Component/CloudInfo';
import DashboardPieChart from './Component/DashboardPieChart';

import MapComponent from './Component/DashboardMap';
import Alert from '../../components/Alert/Alert';

import { fetchInstances } from '../../services/InstanceServices';
import { count } from 'd3';

const Dashboard = () => {
    const [instances, setInstances] = useState([]);
    const [instanceCount, setInstanceCount] = useState([]);
    const [vpnIPCount, setVPNIPCount] = useState([]);

    useEffect(() => {
        fetchInstancesList();
    }, []);

    const fetchInstancesList = async () => {
        try {
            const response = await fetchInstances();
            
            if (response.status === 200) {
                const data = await response.json();
                setInstances(data);
                setInstanceCount(countInstancesByCloud(data));
                setVPNIPCount(countVPNIP(data));
                console.log(data);

            }
        } catch (error) {
            console.error("Error fetching instances:", error);
        }
    }

    const countInstancesByCloud = (instances) => {
        // 🔹 CSP별 색상 지정
        const colorMap = {
            AWS: "#ff9900",      
            Azure: "#0080ff"
        };
        return Object.values(
            instances.reduce((acc, instance) => {
                if (!acc[instance.csp_name]) {
                    acc[instance.csp_name] = {
                        id: Object.keys(acc).length, 
                        label: instance.csp_name, 
                        value: 0, 
                        color: colorMap[instance.csp_name]
                    };
                }
                acc[instance.csp_name].value += 1;
                return acc;
            }, {})
        );
    };
    
    const countVPNIP = (instances) => {
        // 🔹 VPN 상태별 색상 지정
        const colorMap = {
            "VPN Connected": "#00ff00", 
            "VPN Disconnected": "#ff0000" 
        };
    
        // 🔹 초기값 설정
        const result = {
            "VPN Connected": { id: 0, label: "VPN Active", value: 0, color: colorMap["VPN Connected"] },
            "VPN Disconnected": { id: 1, label: "VPN Inactive", value: 0, color: colorMap["VPN Disconnected"] }
        };
    
        instances.forEach((instance) => {
            if (instance.vpn_ip) {
                result["VPN Connected"].value += 1;
            } else {
                result["VPN Disconnected"].value += 1;
            }
        });
    
        return Object.values(result);
    };    
    

    return (
        <div className='flex w-full'>
            <Grid container spacing={2}>
                <Grid item xs={12} md={12} lg={12}>
                    <Paper sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                        <CloudInfo />
                    </Paper>
                </Grid>
                <Grid item xs={12} md={8} lg={9}>
                    <Grid container spacing={2}>
                        <Grid item xs={12} md={12} lg={4}>
                            <Paper sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                                <DashboardPieChart 
                                    // title="클라우드 별 계정 수"
                                    title="Account Count by Cloud"
                                    data={
                                        [
                                            { id: 0, label: "AWS", value: 1, color: "#ff9900" },
                                            { id: 1, label: "Azure", value: 1, color: "#0080ff" }
                                        ]
                                    }
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <Paper sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                                <DashboardPieChart 
                                    // title="Cloud 별 인스턴스 수"
                                    title="Instance Count by Cloud"
                                    data={instanceCount}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={4}>
                            <Paper sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                                <DashboardPieChart 
                                    // title="vpn 연결된 인스턴스 수"
                                    title="VPN Connected Instance Count"
                                    data={vpnIPCount}
                                />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={12} lg={12}>
                            <Paper sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                                <MapComponent 
                                    instances={instances}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                </Grid>
                <Grid item xs={12} md={4} lg={3}>
                    <Paper sx={{ p: 1, height: "100%", display: "flex", flexDirection: "column" }}>
                        <Alert />
                    </Paper>
                </Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;
