import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import HomeHeader from '../components/header/HomeHeader';
import Footer from '../components/footer/Footer';
// import { useCustomPrompt } from '../components/hook/usePrompt';

const HomeLayout = () => {
    return (
        <div className="flex flex-col min-h-screen relative">
            <HomeHeader />
            <div className="flex-grow w-full pt-16">
                <Outlet />
            </div>
            <Footer />
        </div>
    );
};

export default HomeLayout;