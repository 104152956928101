const AppConfig = {
    // App Details
    api_base_url: '/api/v1',
    aws_base_url: '/api/aws',
    azure_base_url: '/api/azure',
};

const getCSP_URL = ({csp}) => {
    switch (csp) {
        case 'AWS':
            return AppConfig.aws_base_url;
        case 'Azure':
            return AppConfig.azure_base_url;
        default:
            return null;
    }
};

export default AppConfig;
export { getCSP_URL };