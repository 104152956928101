import React, { createContext, useState, useRef } from "react";

import { useTranslation } from "react-i18next";

export const ProjectNavBarContext = createContext({
    sections: [],
    scrollToSection: () => {},
});

export const ProjectNavBarProvider = ({ children }) => {
    const { t } = useTranslation(["projectHome", "common"]);

    // const [selectedItem, setSelectedItem] = useState(null);

    const cspmRef = useRef(null);
    const casbRef = useRef(null);
    const reverseProxyRef = useRef(null);
    const uniqueApproachRef = useRef(null);

    const sections = [
        { id: "cspm", label: `1. ${t("CSPM")} (CSPM)`, ref: cspmRef },
        { id: "casb", label: `2. ${t("whatIsCASB")}?`, ref: casbRef },
        { id: "reverseProxy", label: `3. ${t("whyDevelopAReverseProxyBasedCASB")}?`, ref: reverseProxyRef },
        { id: "uniqueApproach", label: `4. ${t("ourUniqueApproach")}?`, ref: uniqueApproachRef },
    ];

    const scrollToSection = (ref) => {
        ref.current.scrollIntoView({ behavior: "smooth", block: "start" });
    };

    return (
        <ProjectNavBarContext.Provider value={{ sections, scrollToSection }}>
            {children}
        </ProjectNavBarContext.Provider>
    );
};