import React, { useState, useContext } from 'react';
import { useParams } from 'react-router-dom';

import { UserContext } from '../../core/user';
import { AuthContext } from '../../core/auth';

import {
    TextField,
    Button,
    Card,
    CardContent,
    Typography,
    Box,
    Container,
    Tooltip,
    IconButton,
    CircularProgress,
} from '@mui/material';

import {
    CloudOutlined,
    KeyOutlined,
    VpnKeyOutlined,
    PublicOutlined,
    InfoOutlined,
    DeleteOutline,
    ContentCopyOutlined,
} from '@mui/icons-material';
import { styled } from '@mui/material/styles';
import { fetchCreateAWSCredential } from '../../services/csp/awsServices';

import { useTranslation } from 'react-i18next';

const StyledCard = styled(Card)(({ theme }) => ({
    maxWidth: 500,
    margin: '40px auto',
    boxShadow: '0 8px 24px rgba(0,0,0,0.12)',
    borderRadius: 16,
    background: 'linear-gradient(145deg, #ffffff 0%, #f5f5f5 100%)',
}));

const Form = styled('form')({
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
});

const InputContainer = styled(Box)({
    position: 'relative',
});

const RegisterAWS = () => {
    const { t } = useTranslation(['project', 'common']);
    const { userState, setUserState } = useContext(UserContext);

    const [formData, setFormData] = useState({
        aws_access_key: '',
        aws_secret_key: '',
        region: 'ap-northeast-2',
    });

    const [showSecret, setShowSecret] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const [isSuccess, setIsSuccess] = useState(false);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsLoading(true);
        setErrorMessage('');
        setIsSuccess(false);

        try {
            const response = await fetchCreateAWSCredential(formData, userState.com_id);

            if (response.status === 200) {
                setIsSuccess(true);
            } else {
                const errorData = await response.json();
                setErrorMessage(errorData.detail || "AWS Credential validation failed.");
            }
        } catch (error) {
            setErrorMessage("Network error or server is unreachable.");
        } finally {
            setIsLoading(false);
        }
    };

    const handleCopyToClipboard = (text) => {
        navigator.clipboard.writeText(text);
    };

    const regionOptions = [
        'ap-northeast-2',
        'us-east-1',
        'us-west-2',
        'eu-west-1',
        'ap-southeast-1',
    ];

    return (
        <Container maxWidth="sm">
            <StyledCard>
                <CardContent sx={{ p: 4 }}>
                    <Box display="flex" alignItems="center" mb={3} style={{justifyContent: 'center'}}>
                        <CloudOutlined
                            sx={{ fontSize: 40, color: '#ff9900', mr: 2 }}
                        />
                        <Typography variant="h4" fontWeight="600">
                            AWS {t("common:Connection")}
                        </Typography>
                    </Box>

                    {isSuccess ? (
                        <Typography variant="h5" sx={{ color: 'green', textAlign: 'center' }}>
                            ✅ {t("RegisterSuccess")}!
                        </Typography>
                    ) : (
                        <Form onSubmit={handleSubmit}>
                            <InputContainer>
                                <TextField
                                    fullWidth
                                    label="Access Key"
                                    variant="outlined"
                                    value={formData.aws_access_key}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            aws_access_key: e.target.value,
                                        })
                                    }
                                    autoComplete='off'
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#ff9900',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#ff9900',
                                            },
                                        },
                                    }}
                                    
                                    InputProps={{
                                        startAdornment: (
                                            <KeyOutlined
                                                sx={{
                                                    mr: 1,
                                                    color: 'text.secondary',
                                                }}
                                            />
                                        ),
                                        endAdornment: (
                                            <IconButton
                                                onClick={() =>
                                                    handleCopyToClipboard(
                                                        formData.aws_access_key,
                                                    )
                                                }
                                            >
                                                <ContentCopyOutlined />
                                            </IconButton>
                                        ),
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <TextField
                                    fullWidth
                                    label="Secret Key"
                                    variant="outlined"
                                    type={showSecret ? 'text' : 'password'}
                                    value={formData.aws_secret_key}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            aws_secret_key: e.target.value,
                                        })
                                    }
                                    autoComplete='off'
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#ff9900',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#ff9900',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <VpnKeyOutlined
                                                sx={{
                                                    mr: 1,
                                                    color: 'text.secondary',
                                                }}
                                            />
                                        ),
                                        endAdornment: (
                                            <Box>
                                                <IconButton
                                                    onClick={() =>
                                                        setShowSecret(!showSecret)
                                                    }
                                                >
                                                    {showSecret ? (
                                                        <DeleteOutline />
                                                    ) : (
                                                        <InfoOutlined />
                                                    )}
                                                </IconButton>
                                            </Box>
                                        ),
                                    }}
                                />
                            </InputContainer>

                            <InputContainer>
                                <TextField
                                    fullWidth
                                    select
                                    label="Region"
                                    variant="outlined"
                                    value={formData.region}
                                    onChange={(e) =>
                                        setFormData({
                                            ...formData,
                                            region: e.target.value,
                                        })
                                    }
                                    sx={{
                                        '& label.Mui-focused': {
                                            color: '#ff9900',
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            '&.Mui-focused fieldset': {
                                            borderColor: '#ff9900',
                                            },
                                        },
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <PublicOutlined
                                                sx={{
                                                    mr: 1,
                                                    color: 'text.secondary',
                                                }}
                                            />
                                        ),
                                    }}
                                    SelectProps={{
                                        native: true,
                                    }}
                                >
                                    {regionOptions.map((option) => (
                                        <option key={option} value={option}>
                                            {option}
                                        </option>
                                    ))}
                                </TextField>
                            </InputContainer>
                            
                            {errorMessage && (
                                <Typography 
                                    variant="body2" 
                                    sx={{ color: 'red', textAlign: 'center', mt: 2 }}
                                >
                                    ❌ {errorMessage}
                                </Typography>
                            )}

                            <Button
                                variant="contained"
                                size="large"
                                type="submit"
                                sx={{
                                    mt: 2,
                                    height: 56,
                                    background: "linear-gradient(45deg, #FF9900 30%, #FFB74D 90%)", // AWS 오렌지 계열
                                    boxShadow: "0 3px 5px 2px rgba(255, 153, 0, .3)", // 오렌지 계열 그림자
                                    textTransform: 'none',
                                    "&:hover": {
                                        background: "linear-gradient(45deg, #E67E22 30%, #FFAA33 90%)", // 더 진한 주황색 그라데이션
                                    },
                                }}
                                startIcon={<CloudOutlined />}
                                disabled={isLoading}
                            >
                                {isLoading ? <CircularProgress size={24} color="inherit" /> : t("RegisterAWSConnection")}
                            </Button>
                        </Form>
                    )}
                </CardContent>
            </StyledCard>
        </Container>
    );
};

export default RegisterAWS;
