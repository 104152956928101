import React, { useContext, useState } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import Logo from '../static/img/csrc_logo_big.jpg';

import {
    Avatar,
    Box,
    Container,
    CssBaseline,
    Divider,
    Typography,
    Grid,
} from '@mui/material';

const AuthLayout = () => {
    return (
        <Grid container sx={{ height: '100vh' }}>
            <Grid
                item
                xs={5.95}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Box sx={{ textAlign: 'center' }}>
                    <img
                        src={Logo}
                        className="icon-lg"
                        alt="CSRC Logo"
                        style={{ maxWidth: '100%', height: 'auto' }}
                    />
                    <Typography
                        variant="h6"
                        color="secondary"
                        sx={{
                            paddingTop: 2,
                            fontWeight: 'bold',
                            fontSize: '2rem',
                        }}
                    >
                        PNU Cloud Access Security Broker
                    </Typography>
                </Box>
            </Grid>
            <Grid item xs={0.1}>
                <Divider orientation="vertical" sx={{ height: '100%' }} />
            </Grid>
            <Grid
                item
                xs={5.95}
                sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}
            >
                <Container component="main" maxWidth="xs">
                    <Outlet/>
                </Container>
            </Grid>
        </Grid>
    );
};

export default AuthLayout;
