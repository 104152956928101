import React from 'react';
import { createBrowserRouter, Navigate } from 'react-router-dom';

// layouts
import MainLayout from '../layouts/MainLayout';
import ErrorLayout from '../layouts/ErrorLayout';
import HomeLayout from '../layouts/HomeLayout';
import AuthLayout from '../layouts/AuthLayout';
import ProjectLayout from '../layouts/ProjectLayout';

// routes
import RedirectionRoute from '../components/route/RedirectionRoute';
import ProtectedRoute from '../components/route/ProtectedRoute';

import SignIn from '../pages/authentication/SignIn';
import SignUp from '../pages/authentication/SignUp';

import MyPage from '../pages/myPages/MyPage';

import RegisterCSP from '../pages/csp/RegisterCSP';

import Dashboard from '../pages/dashboard/Dashboard';
import AccessControlListPage from '../pages/pnu_acl/AccessControlListPage';
import InstanceListPage from '../pages/instance/InstanceListPage';
import InstanceDetailPage from '../pages/instance/InstanceDetailPage';
import Project from '../pages/home/Project';
import Home from '../pages/home/Home';
import UEBA from '../pages/ueba/UEBA';
import App from '../App';

const router = createBrowserRouter([
    {
        path: '/:lang?',
        element: <MainLayout />,
        errorElement: <ErrorLayout />,
        children: [
            {
                index: true,
                element: <RedirectionRoute />,
            },
            {
                path: 'home',
                element: <HomeLayout />,
                children: [
                    {
                        index: true,
                        element: <Home />,
                    },
                ],
            },
            {
                element: (
                    <AuthLayout />
                ),
                children: [
                    {
                        index: true,
                        path: 'SignIn',
                        element: <SignIn />,
                    },
                    {
                        path: 'SignUp',
                        element: <SignUp />,
                    }
                ]
            },
            {
                element: (
                    <ProtectedRoute>
                        <ProjectLayout />
                    </ProtectedRoute>
                ),
                children: [
                    {
                        index: true,
                        path: 'what-is-casb',
                        element: <Project />,
                    },
                    {
                        index: true,
                        path: 'Dashboard',
                        element: <Dashboard />,
                    },
                    {
                        path: 'Instance',
                        children: [
                            {
                                index: true,
                                element: <InstanceListPage />,
                            },
                            {
                                path: ':csp_name/:iid',
                                element: <InstanceDetailPage />,
                            },
                        ]
                    },
                    {
                        path: 'ACL',
                        children: [
                            {
                                index: true,
                                element: <AccessControlListPage />,
                            },
                        ]
                    },
                    {
                        path: 'UEBA',
                        children: [
                            {
                                index: true,
                                element: <UEBA />,
                            },
                        ]
                    },
                    {
                        path: 'MyPage',
                        children: [
                            {
                                index: true,
                                element: <MyPage />,
                            },
                        ]
                    },      
                ]
            },
        ],
    },
]);

export default router;
