import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material';

import { 
  XAxis, 
  YAxis, 
  Tooltip, 
  CartesianGrid, 
  ResponsiveContainer, 
  BarChart,
  Bar,
  Legend
} from 'recharts';

import dayjs from 'dayjs';

import {
  fetchInstanceSyscallAnomalyCount
} from '../../../services/InstanceServices';

import { useTranslation } from 'react-i18next';

const test = [
  {
    "name": "Page A",
    "uv": 4000,
    "pv": 2400
  },
  {
    "name": "Page B",
    "uv": 3000,
    "pv": 1398
  },
  {
    "name": "Page C",
    "uv": 2000,
    "pv": 9800
  },
  {
    "name": "Page D",
    "uv": 2780,
    "pv": 3908
  },
  {
    "name": "Page E",
    "uv": 1890,
    "pv": 4800
  },
  {
    "name": "Page F",
    "uv": 2390,
    "pv": 3800
  },
  {
    "name": "Page G",
    "uv": 3490,
    "pv": 4300
  }
]


const AnomalyCountBar = ({iid}) =>{
  const { t } = useTranslation('project');
  const [data, setData] = useState([]);
  const [timeFilter, setTimeFilter] = useState('1h');

  useEffect(() => {
    fetchAnomalyCount(iid);
  }, [iid]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAnomalyCount(iid);
    }, 60000);
    return () => clearInterval(interval);
  }, [timeFilter]);

  useEffect(() => {
    fetchAnomalyCount(iid);
  }, [timeFilter]);

  const fetchAnomalyCount = async (iid) => {
    try{
      const response = await fetchInstanceSyscallAnomalyCount(iid, timeFilter);
      const data = await response.json();

      setData(data);
    } catch(error){
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* column alignment */}
      <div className='flex flex-row items-center justify-between'>
        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'left' }}>{t("anomalyCount")}</Typography>
        <Select 
          value={timeFilter} 
          onChange={(e) => setTimeFilter(e.target.value)} 
          sx={{ 
            alignSelf: 'center', 
            fontSize: '15px',
            height: 30,
            minWidth: 150,
            // padding: '5px 10px',
          }}
        >
          <MenuItem value="15m">{t("last15Minutes")}</MenuItem>
          <MenuItem value="30m">{t("last30Minutes")}</MenuItem>
          <MenuItem value="1h">{t("last1Hour")}</MenuItem>
          <MenuItem value="6h">{t("last6Hours")}</MenuItem>
          <MenuItem value="1d">{t("last1Day")}</MenuItem>
          <MenuItem value="7d">{t("last7Days")}</MenuItem>
          <MenuItem value="all">{t("allTime")}</MenuItem>
        </Select>
      </div>
      <Box sx={{ height: 250 }}>
        <ResponsiveContainer width="100%" height={200}>
          <BarChart width={730} height={250} data={data}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="time" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="count" fill="#8884d8" />
          </BarChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default AnomalyCountBar;