import React, { useRef, useState, useEffect } from "react";

import { useNavigate } from 'react-router-dom';

import {
    Button
} from "@mui/material";

import csrc_logo from "../../assets/images/csrc_logo.png";
import casb_architecture from "../../assets/images/casb_architecture.png";

import background from "../../assets/images/Home/homeBackground.jpg";
import cloud_synchronization from "../../assets/images/Home/cloud_synchronization.png";
import access_control_list from "../../assets/images/Home/access_control_list.png";
import instance_monitoring from "../../assets/images/Home/instance_monitoring.png";

import home from "../../assets/images/Home/home.jpg";
import home2 from "../../assets/images/Home/home2.jpg";
import home3 from "../../assets/images/Home/home3.jpg";
import home4 from "../../assets/images/Home/home4.jpg";
import home5 from "../../assets/images/Home/home5.jpg";
import rmfHome from "../../assets/images/Home/rmfHome.jpg";
import pentestingHome from "../../assets/images/Home/pentestingHome.png";

import multi_cloud from "../../assets/images/Home/multi_cloud.png";
import network_tunneling from "../../assets/images/Home/network_tunneling.png";
import access_control from "../../assets/images/Home/access_control.png";
import ai from "../../assets/images/Home/ai.png";

import { useTranslation } from "react-i18next";


const Home = () => {
    const { t } = useTranslation("home");
    
    const navigate = useNavigate();
    const [selectedFeatureIndex, setSelectedFeatureIndex] = useState(0);

    const featureData = [
        {
            title: t("feature1Title"),
            description: t("feature1Description"),
            image: cloud_synchronization,
        },
        {
            title: t("feature2Title"),
            description: t("feature2Description"),
            image: access_control_list,
        },
        {
            title: t("feature3Title"),
            description: t("feature3Description"),
            image: instance_monitoring, // Replace with actual image
        },
    ];
    
    const relatedProducts = [
        {
            title: t("research1Title"),
            description: t("research1Description"),
            image: rmfHome, // Replace with actual image
            link: "#",
        },
        {
            title: t("research2Title"),
            description: t("research2Description"),
            image: pentestingHome, // Replace with actual image
            link: "#",
        },
        {
            title: t("research3Title"),
            description: t("research3Description"),
            image: csrc_logo, // Replace with actual image
            link: "#",
        },
    ];

    return (
        <div className="w-full min-h-screen text-white">
            {/* Header Section */}
            <div className="bg-blue-600 text-white  pl-6 md:pl-12 lg:pl-24  w-full">
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-2 mx-auto"> 
                    <div className="flex flex-col justify-center w-full max-w-[800px] ml-auto">
                        {/* <h1 className="text-4xl font-bold">PNU ZeroTrust CSRC CASB </h1> */}
                        <p 
                            style={{fontSize: "48px", fontWeight: "bold"}}
                        >
                            {t("title")}
                        </p>
                        <p className="mt-4 text-lg">
                            {t("subtitle")}
                        </p>
                        <div className="mt-6 flex gap-4 jestify-center">
                            <Button 
                                // background color to white and text color to blue-600
                                sx={{
                                    bgcolor: "white",
                                    color: "black",
                                    fontWeight: "bold",
                                    px: 2,
                                    py: 1,
                                    borderRadius: "5px",
                                    boxShadow: 3,
                                    transition: "all 0.3s",
                                    "&:hover": {
                                        bgcolor: "#e2e2e2",
                                        color: "black",
                                    },
                                    "&:active": {
                                        bgcolor: "#e2e2e2",
                                    },
                                    fontSize: "10pt",
                                }}
                                // className="bg-white text-blue-600 font-semibold px-6 py-2 rounded-lg shadow-md"

                                onClick={() => {
                                    
                                }}
                            >
                            
                                {t("titleButton1")}
                            </Button>
                            <Button
                                sx={{
                                    bgcolor: "white",
                                    color: "black",
                                    fontWeight: "bold",
                                    px: 2,
                                    py: 1,
                                    borderRadius: "5px",
                                    boxShadow: 3,
                                    transition: "all 0.3s",
                                    "&:hover": {
                                        bgcolor: "#e2e2e2",
                                        color: "black",
                                    },
                                    "&:active": {
                                        bgcolor: "#e2e2e2",
                                    },
                                    fontSize: "10pt",
                                }}
                                onClick={() => {
                                    navigate('/SignIn');
                                }}
                            >
                            
                                {t("titleButton2")}
                            </Button>
                        </div>
                    </div>
                    <div className="relative w-full h-full overflow-hidden">
                        <svg 
                            className="absolute top-0 left-0 w-full h-full z-10 pointer-events-none" 
                            viewBox="0 0 250 100" 
                            preserveAspectRatio="none"
                        >
                            <path 
                                d="M40,0 
                                    Q7,0 15,20
                                    Q0,25 0,35
                                    Q0,45 15,50
                                    Q0,75 45,80
                                    Q50,100 100,100
                                    L0,100
                                    L0,0
                                    Z"
                                fill="#2563EB" 
                                opacity="1"
                            />
                        </svg>
                        <img 
                            src={home5} 
                            className="w-full h-full object-cover z-10"
                        />
                    </div>
                </div>
            </div>
            
            {/* Feature Section */}
            <div className="bg-white text-black py-16 px-6 md:px-12 lg:px-24 w-full">
                <h2 className="text-3xl font-bold text-center">{t("section1Title")}</h2>
                <p className="text-center mt-5 mb-12">
                    {t("section1Subtitle")}
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8 max-w-[1600px] mx-auto">
                    <div className="flex flex-col items-start text-left">
                        <img src={multi_cloud} style={{width: "60px", height: "60px"}}/>
                        <h3 className="font-semibold text-xl mt-1">
                            {t("capabilityTitle1")}
                        </h3>
                        <p className="mt-2">{t("capabilityDescription1")}</p>
                    </div>
                    <div className="flex flex-col items-start text-left">
                        <img src={network_tunneling} style={{width: "60px", height: "60px", padding: "4px"}}/>
                        <h3 className="font-semibold text-xl mt-1">
                            {t("capabilityTitle2")}
                        </h3>
                        <p className="mt-2">{t("capabilityDescription2")}</p>
                    </div>
                    <div className="flex flex-col items-left text-left">
                        <img src={access_control} style={{width: "60px", height: "60px", padding: "2px"}}/>
                        <h3 className="font-semibold text-xl mt-1">
                            {t("capabilityTitle3")}
                        </h3>
                        <p className="mt-2">{t("capabilityDescription3")}</p>
                    </div>
                    <div className="flex flex-col items-left text-lef">
                        <img src={ai} style={{width: "60px", height: "60px", padding: "2px"}}/>
                        <h3 className="font-semibold text-xl mt-1">{t("capabilityTitle4")}</h3>
                        <p className="mt-2">{t("capabilityDescription4")}</p>
                    </div>
                </div>
            </div>
            <div className="bg-[#F2F2F2] text-black py-16 px-12 md:px-20 w-full">
                <h2 className="text-3xl font-bold text-center">{t("section2Title")}</h2>
                <p className="text-center mt-5 mb-12">
                    {t("section2Subtitle")}
                </p>
                
                <div className="grid grid-cols-1 md:grid-cols-2 gap-8 max-w-[1600px] mx-auto">
                    <div className="text-center md:text-left">
                        <h3 className="font-semibold text-xl mt-4">{t("section2ImageTitle")}</h3>
                        <p className="mt-2">{t("section2ImageDescription")}</p>
                    </div>
                    <div className="flex justify-center">
                        <img src={casb_architecture} alt="CASB Architecture" className="w-3/4 md:w-4/5 lg:w-full  rounded-lg shadow-lg" />
                    </div>
                </div>
            </div>
            <div className="bg-white text-black py-16 px-12 md:px-20 w-full">
                <h2 className="text-3xl font-bold text-center">{t("section3Title")}</h2>
                <div className="grid grid-cols-1 md:grid-cols-4 gap-8 max-w-[1600px] mx-auto mt-12 items-start">
                    <div className="flex flex-col space-y-4">
                        {featureData.map((feature, index) => (
                            <button
                                key={index}
                                className={`w-full text-left px-4 py-2 border-l-4 font-semibold transition-all duration-300 ${
                                    selectedFeatureIndex === index ? "border-blue-600 text-blue-600" : "border-gray-300 text-gray-600"
                                }`}
                                onClick={() => setSelectedFeatureIndex(index)}
                            >
                                {feature.title}
                            </button>
                        ))}
                    </div>
                    
                    <div className="md:col-span-3 flex flex-col text-left min-h-[500px] w-full relative">
                        <div className="flex flex-col items-center justify-center w-full h-full relative">
                            <img
                                src={background} alt="Background"
                                className="absolute inset-0 w-full h-full object-cover rounded-lg opacity-90 z-0"
                            />
                            <img 
                                src={featureData[selectedFeatureIndex].image} alt={featureData[selectedFeatureIndex].title} 
                                className="w-full max-w h-auto rounded-lg relative z-10 scale-90"
                            />
                        </div>
                        <h3 className="mt-8 font-semibold text-xl">{featureData[selectedFeatureIndex].title}</h3>
                        <p className="mt-2 text-lg">{featureData[selectedFeatureIndex].description}</p>
                    </div>
                </div>
            </div>
            <div className="bg-[#F2F2F2] text-black py-16 px-12 md:px-20 w-full">
                <h2 className="text-3xl font-bold text-center">{t("section4Title")}</h2>
                <p className="text-center mt-5">{t("section4Subtitle")}</p>
                <div className="text-center mt-4">
                    <a href="#" className="text-blue-600 font-semibold">{t("detailedInfo")} &gt;</a>
                </div>
                
                <div className="grid grid-cols-1 md:grid-cols-3 gap-8 max-w-[1600px] mx-auto mt-12">
                    {relatedProducts.map((product, index) => (
                        <div key={index} className="bg-white shadow-lg rounded-lg overflow-hidden">
                            <img src={product.image} alt={product.title} className="w-full h-64 object-cover" />
                            <div className="p-6">
                                <h3 className="font-semibold text-xl">{product.title}</h3>
                                <p className="mt-2 text-gray-700">{product.description}</p>
                                <div className="mt-4">
                                    <a href={product.link} className="text-blue-600 font-semibold">{t("detailedInfo")} &gt;</a>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Home;
