import React, { useEffect, useState, useRef } from "react";
import {
    List,
    ListItemButton,
    ListItemText,
    Box,
    CssBaseline,
    Typography,
} from '@mui/material';

import { useTranslation } from 'react-i18next';
import { fetchInstanceAlert } from '../../../services/InstanceServices';

// ✅ 샘플 데이터
const alertData = [
  { type: "Unauthorized Access", time: "2025-03-10 15:30:00", anomaly_score: 95, threshold: 80, degree: "HIGH" },
  { type: "Malware Detected", time: "2025-03-10 14:50:00", anomaly_score: 88, threshold: 75, degree: "MEDIUM" },
  { type: "Unusual Data Transfer", time: "2025-03-10 13:15:00", anomaly_score: 76, threshold: 70, degree: "LOW" },
  { type: "Multiple Failed Logins", time: "2025-03-10 12:45:00", anomaly_score: 92, threshold: 85, degree: "HIGH" },
  { type: "Sensitive File Access", time: "2025-03-10 11:30:00", anomaly_score: 81, threshold: 75, degree: "MEDIUM" },
];

const Alert = ({ iid }) => {
    const { t } = useTranslation(['project', 'common']);
    const ref = useRef(null);
    const [alerts, setAlerts] = useState(alertData);  // ✅ 기본 샘플 데이터 추가
    const [count, setCount] = useState(0);

    useEffect(() => {
        ref.current.ownerDocument.body.scrollTop = 0;
        // if (iid) {
        //     fetchAlerts();
        // }
    }, [iid]);

    // ✅ Alerts 가져오기
    const fetchAlerts = async () => {
        if (!iid) return;  // ✅ iid 없으면 실행하지 않음
        try {
            const response = await fetchInstanceAlert(iid, count + 5);
            const data = await response.json();
            setAlerts(prevAlerts => [...prevAlerts, ...processAlerts(data)]);
            setCount(count + 5);
        } catch (error) {
            console.error("Error fetching alerts:", error);
        }
    }

    // ✅ Alerts 데이터 가공
    const processAlerts = (alerts) => {
        return alerts.map(alert => ({
            primary: t(alert.type),
            secondary: (
                <>
                    {t("common:Time")}: {alert.time} <br />
                    {t("anomalyScore")}: {alert.anomaly_score} <br />
                    {t("threshold")}: {alert.threshold}
                </>
            ),
            degree: alert.degree
        }));
    };

    // ✅ degree에 따른 색상 지정
    const getAlertColor = (degree) => {
        switch (degree) {
            case "HIGH":
                return "red"; // 🔴
            case "MEDIUM":
                return "orange"; // 🟠
            case "LOW":
                return "yellow"; // 🟡
            default:
                return "gray"; // ⚪
        }
    };

    return (
        <div
            style={{
                display: "flex",
                flexDirection: "column",
                height: "100%",
                overflow: "hidden",
            }}
            ref={ref}
        >
            <CssBaseline />
            <div className='flex pl-3'>
                <Typography variant="h5">
                    {t("Alert")}
                </Typography>
            </div>
            <Box
                sx={{
                    flexGrow: 1,  // ✅ 높이를 가득 채우도록 설정
                    overflow: "auto",  // ✅ 스크롤 가능하도록 설정
                    maxHeight: "750px", // ✅ 최대 높이 설정
                }}
            >
                <List>
                    {alerts.map(({ type, time, anomaly_score, degree, threshold }, index) => (
                        <ListItemButton
                            key={index} 
                            sx={{
                                borderLeft: `5px solid ${getAlertColor(degree)}`, // ✅ degree에 따라 색상 변경
                                py: "0.25px",
                                pl: "10px",
                                pr: "3px",
                            }}
                        >
                            <ListItemText 
                                primary={type} 
                                secondary={
                                    <>
                                        {t("common:Time")}: {time} <br />
                                        {t("anomalyScore")}: {anomaly_score} <br />
                                        {t("threshold")}: {threshold}
                                    </>
                                } 
                            />
                        </ListItemButton>
                    ))}
                    {/* ✅ Show More 버튼 */}
                    <div className="flex justify-center">
                        <button 
                            className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-2"
                            onClick={fetchAlerts}
                        >
                            {t("common:ShowMore")}
                        </button>
                    </div>
                </List>
            </Box>
        </div>
    );
}

export default Alert;
