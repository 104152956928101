import React from "react";

import {
    Box,
    Typography,
    FormControl,
    Select,
    MenuItem
} from "@mui/material";

import aws_logo from '../../../assets/images/AWS_Logo.svg';
import azure_logo from '../../../assets/images/Azure_Logo.svg';
import openstack_logo from '../../../assets/images/Openstack_Logo.svg';

import { useTranslation } from "react-i18next";

const UserInfo = ({instances, selectedInstance, handleInstanceChange}) => {
    const { t } = useTranslation(["project", "common"]);



    const getCspLogo = (csp) => {
        switch (csp) {
          case 'AWS':
            return aws_logo;
          case 'Azure':
            return azure_logo;
          case 'OpenStack':
            return openstack_logo;
          default:
            return null;
        }
    };

    const getCspFullName = (csp) => {
        switch (csp) {
          case 'AWS':
            return t("amazonWebServices");
          case 'Azure':
            return t("microsoftAzure");
          case 'OpenStack':
            return 'OpenStack';
          default:
            return null;
        }
    };

    return (
        <div>
            <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
                {/* "Name:" 텍스트 */}
                <Typography sx={{ mr: 1, fontWeight: "bold" }}>
                    {t("User")}: Hacker
                </Typography>

                {/* Select 박스 (기본 값으로 instance_name 표시) */}
            </Box>

            {/* <Typography variant="h6" color="text.secondary" sx={{ mb: 2 }}>
                (ID: {selectedInstance.instance_id})
            </Typography> */}

            {/* 🔹 CSP + 로고 */}
            {/* <Box sx={{ display: "flex", alignItems: "center", mb: 1 }}>
                <Typography sx={{ fontWeight: "bold", mr: 1 }}>
                CSP: {getCspFullName(selectedInstance.csp_name)}
                </Typography>
                {getCspLogo(selectedInstance.csp_name) && (
                <img
                    src={getCspLogo(selectedInstance.csp_name)}
                    alt={`${selectedInstance.csp_name} Logo`}
                    style={{ height: 20 }}
                />
                )}
            </Box> */}

            {/* 🔹 상세 정보 */}
            <Box sx={{ pl: 1 }}>
                <Typography>- {t("Status")}: Active</Typography>
                {/* <Typography>- {t("instanceOS")}: {selectedInstance.instance_os}</Typography> */}
                <Typography>- {t("publicIP")}: 164.125.37.12</Typography>
                <Typography>- {t("vpnIP")}: 10.8.0.10</Typography>
                {/* <Typography>- VPC ID: {selectedInstance.vpc_id}</Typography> */}
                {/* <Typography>
                - Group Name:{" "}
                {selectedInstance.security_groups
                    ? JSON.parse(selectedInstance.security_groups)
                        .map((group) => group.GroupName)
                        .join(", ")
                    : "N/A"}
                </Typography> */}
                <Typography>- {t("Role")}: Developer</Typography>
            </Box>
        </div>
    )
};

export default UserInfo;