import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Typography, 
  Select,
  MenuItem,
  TextField,
  Button
} from '@mui/material';

import { 
  LineChart, 
  Line, 
  XAxis, 
  YAxis, 
  Tooltip, 
  CartesianGrid, 
  ResponsiveContainer, 
  ReferenceLine 
} from 'recharts';

import dayjs from 'dayjs';

import {
  fetchInstanceThreshold,
  fetchInstanceSyscallAnomalyAverageScores,
  fetchInstanceSyscallAnomalyMaxScores
} from '../../../services/InstanceServices';

import { useTranslation } from 'react-i18next';

const BehaviorAnalytics = ({iid, type, threshold, setThreshold}) =>{
  const { t } = useTranslation('project');
  const [data, setData] = useState([]);
  const [timeFilter, setTimeFilter] = useState('1h');
  const [subtitle, setSubTitle] = useState(t("anomalyScores"));

  useEffect(() => {
    setSubTitle(type === 'mean' ? t("averageAnomalyScores") : t("maxAnomalyScores"));

    fetchInstanceThreshold(iid).then(response => {
      if (response.ok) {
        response.json().then(data => {
          setThreshold(data.threshold);
        });
      }
    });
  }, [iid]);

  useEffect(() => {
    const interval = setInterval(() => {
      fetchAnomalyData(iid, type);
    }, 60000);
    return () => clearInterval(interval);
  }, [timeFilter]);

  useEffect(() => {
    fetchAnomalyData(iid, type);
  }, [timeFilter]);

  const fetchAnomalyData = async (iid, type) => {
    try{
      let response;
      if (type === 'mean') {
        response = await fetchInstanceSyscallAnomalyAverageScores(iid, timeFilter);
      } else if (type === 'max') {
        response = await fetchInstanceSyscallAnomalyMaxScores(iid, timeFilter);
      }
      const data = await response.json();

      const transformedData = data.map(item => ({
        ...item,
        score: (item.score * 100).toFixed(2),
        time: dayjs(item.time).format('YYYY-MM-DD HH:mm'),
      }));

      setData(transformedData);
    } catch(error){
      console.error(error);
    }
  };

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      {/* column alignment */}
      <div className='flex flex-row items-center justify-between'>
        <Typography variant="body1" sx={{ fontWeight: 'bold', textAlign: 'left' }}>{subtitle}</Typography>
        <Select 
          value={timeFilter} 
          onChange={(e) => setTimeFilter(e.target.value)} 
          sx={{ 
            alignSelf: 'center', 
            fontSize: '15px',
            height: 30,
            minWidth: 150,
            // padding: '5px 10px',
          }}
        >
          <MenuItem value="15m">{t("last15Minutes")}</MenuItem>
          <MenuItem value="30m">{t("last30Minutes")}</MenuItem>
          <MenuItem value="1h">{t("last1Hour")}</MenuItem>
          <MenuItem value="6h">{t("last6Hours")}</MenuItem>
          <MenuItem value="1d">{t("last1Day")}</MenuItem>
          <MenuItem value="7d">{t("last7Days")}</MenuItem>
          <MenuItem value="all">{t("allTime")}</MenuItem>
        </Select>
      </div>
      <Box sx={{ height: 250 }}>
        <ResponsiveContainer width="100%" height={200}>
          <LineChart 
            data={data} 
            margin={{ bottom: 5 }}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis 
              dataKey="time" 
              tickFormatter={(time) => dayjs(time).format('MM-DD HH:mm')} // ✅ 시간 포맷 변경
              minTickGap={30} // ✅ X축 라벨 간격 조정
            />
            <YAxis 
              domain={[0, 100]} 
              ticks={[0, 20, 40, 60, 80, 100]} 
            />
            <Tooltip 
              labelFormatter={(label) => `Time: ${label}`} // ✅ Tooltip 라벨 포맷 변경
            />
            <ReferenceLine y={threshold} stroke="red" strokeDasharray="3 3" label={`${t("threshold")} - ${threshold}`} />
            <Line 
              type="monotone" 
              dataKey="score" 
              stroke="black" 
              strokeWidth={1} 
              dot={false} 
            /> 
          </LineChart>
        </ResponsiveContainer>
      </Box>
    </Box>
  );
}

export default BehaviorAnalytics;