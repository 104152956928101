import AppConfig from '../core/config';
import { fetchRefresh } from './AuthServices';

// Fetch ACLs by username
const fetchACLs = async () => {
    const url = `${AppConfig.api_base_url}/acl/acls/`;

    const response = await fetch(url, {
        method: 'GET',
        headers: {
            Accept: 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'GET',
                headers: {
                    Accept: 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

// Add ACL by username and iid
const addACL = async (uid, iid, data) => {
    const url = `${AppConfig.api_base_url}/acl/addACL/${uid}/${iid}`;
    const response = await fetch(url, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
        body: JSON.stringify(data),
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
                body: JSON.stringify(data),
            });

            return retryResponse;
        }
    }

    return response;
}

const deleteACL = async (aid) => {
    const url = `${AppConfig.api_base_url}/acl/deleteACL/${aid}`;
    const response = await fetch(url, {
        method: 'DELETE',
        headers: {
            'Content-Type': 'application/json',
        },
        credentials: 'include',
    });

    if (response.status === 401 || response.status === 422) {
        const refreshResponse = await fetchRefresh();

        if (refreshResponse.status === 200) {
            const retryResponse = await fetch(url, {
                method: 'DELETE',
                headers: {
                    'Content-Type': 'application/json',
                },
                credentials: 'include',
            });

            return retryResponse;
        }
    }

    return response;
}

export { fetchACLs, addACL, deleteACL };