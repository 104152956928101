import React, { useState } from 'react';

import { 
    Typography, 
} from '@mui/material';

import { PieChart } from '@mui/x-charts/PieChart';

import { useTranslation } from 'react-i18next';

const DashboardPieChart = ({title, data=[]}) => {
    const {t} = useTranslation('project');

    // console.log(data);
    return (
        <div className='flex w-full flex-col'>
            <Typography variant="h6">
                {t(title)}
            </Typography>
            <div className='flex w-full justify-center'>
                <PieChart
                    series={[
                        {  
                            arcLabel: 'value',
                            data: data,
                        },
                    ]}
                    width={400}
                    height={200}
                />
            </div>
        </div>
    );
};

export default DashboardPieChart;
