import React, { useContext, useState } from 'react';
import { styled } from '@mui/material/styles';
import { Box, Container, Grid, Paper, Typography, Link } from '@mui/material';
import ACLTable from './AccessControlList';
import { UserContext } from '../../core/user';

const AccessControlListPage = () => {
    const { userState } = useContext(UserContext);
    return (
        <Box
            component="main"
            sx={{
                flexGrow: 1,
                // height: '100vh',
                overflow: 'auto',
                width: '100%',
            }}
        >
            <Grid container spacing={3} sx={{ display: 'flex', width: '100%'}}>
                {/* Access Control List, Size is Max */}
                <Grid item xs={12} sx={{ width: '100%'}}>
                    <Paper
                        sx={{
                            p: 2,
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                        }}
                    >
                        <ACLTable username={userState.username} />
                    </Paper>
                </Grid>
            </Grid>
        </Box>
    );
};

export default AccessControlListPage;
