import React from 'react';
import Logo from '../../static/img/csrc_logo_big.jpg';

const Footer = () => {
    return (
        <div
            className="flex justify-center w-full items-center py-4 border-t border-gray-400 bg-white"
        >
            <img src={Logo} alt="logo" className="h-16" />
            <div className="flex flex-col text-xs">
                <p>
                    Copyright ⓒ 제로트러스트 클라우드 보안 연구센터. All Rights Reserved.
                </p>
            </div>
        </div>
    );
};

export default Footer;